import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import uuidv4 from 'uuid/v4';
import styles from './RegistrationFlowPage.scss';
import { isValidEmail } from '../../utils/utils';
import Logo from '../../../../myndlift-signup/public/assets/modern_logo_black.png';
// import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
// import { TestimonialsCustomSwiper } from './TestimonialsCustomSwiper/TestimonialsCustomSwiper';

/* eslint-disable no-undef */
/* eslint-disable react/no-unknown-property */

const environmentConfig =
  window.environmentConfig[window.environmentConfig.current];

// eslint-disable-next-line no-restricted-globals
// const isProd = !(environmentConfig.isDev || location.hostname === 'localhost');

// const AB_TESTING_ELIGIBILITY_PARAM_NAME = 'ab_elgibility';

const RegistrationFlowPageBase = ({
  onComponentReadyToRender,
  deepLinkCouponCode,
}) => {
  // const [videoTestimonials] = useFirebaseDB({
  //   path: '/remoteClinics/videoTestimonials',
  // });

  const urlSearchParams = new URLSearchParams(window.location.search);
  const userId = urlSearchParams.get('userId');
  const groupId = urlSearchParams.get('groupId');
  const utmSource = urlSearchParams.get('utm_source');
  const utmCampaign = urlSearchParams.get('utm_campaign');
  // const couponCodeURL = urlSearchParams.get('couponCode');
  // const nodeURL = environmentConfig.appengine.node;
  const funcURL = environmentConfig.functions;
  const eligibilityId = uuidv4();

  const checkboxInputRef = useRef(null);
  const radioboxInputRef = useRef(null);

  const [isComponentReadyToRender, setIsComponentReadyToRender] = useState(
    false
  );
  const [currentQuestion, setCurrentQuestion] = useState(
    userId ? 'approveAddingFamilyMember' : 'forWho'
  );
  const [answers, setAnswers] = useState({});
  const [prevQuestions, setPrevQuestions] = useState([]);
  const [emailInput, setEmailInput] = useState('');
  const [currentSlideIsLeft, setCurrentSlideIsLeft] = useState(true);
  const [otherInput, setOtherInput] = useState('');

  // Family flow data
  const [isOldBilling, setIsOldBilling] = useState(false);
  const [familyPrice, setFamilyPrice] = useState(false);
  // const [priceWithoutHeadset,setPriceWithoutHeadset] = useState(false);
  const [
    newPlansCheapestPriceForFamily,
    setNewPlansCheapestPriceForFamily,
  ] = useState(false);

  const [eventTimeout, setEventTimeout] = useState(false);

  // const [ABTestimonialToggle, setABTestimonialToggle] = useState(false);

  // eslint-disable-next-line
  const [discountCouponAmount, setDiscountCouponAmount] = useState('');

  const checkOtherEligibility = () => {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  const showFamilyText = userId || answers.forWho === 'A family member';

  const questions = {
    approveAddingFamilyMember: {
      id: 'approveAddingFamilyMember',
      type: 'content',
      title: 'Add a family member/friend',
      details: {
        html: isOldBilling ? (
          <div style={{ fontSize: 20 }}>
            The package costs ${familyPrice} and includes 3 live sessions with
            the Neuro Coach and lifetime access to the Myndlift app.
          </div>
        ) : (
          <div style={{ fontSize: 20 }}>
            <div>
              Starting at ${newPlansCheapestPriceForFamily}/month and includes
              Neuro Coach consultations and access to the Myndlift app.
            </div>
            <div>Does not include new hardware.</div>
          </div>
        ),
      },
      defaultNextQuestion: 'age',
      completedPercentage: 10,
      btnText: "Let's do it!",
    },
    forWho: {
      id: 'forWho',
      type: 'radio',
      title:
        'Hello 👋\nLet’s take 10 seconds to see if Myndlift is a good fit for you!',
      subTitle: 'Who is interested in using Myndlift?',
      details: {
        options: ['Me', 'A family member', 'My clients'],
        score: [100, 0, 100],
      },
      defaultNextQuestion: 'age',
      nextQuestion: {
        'My clients': 'exit-a',
      },
      completedPercentage: 10,
    },
    age: {
      id: 'age',
      type: 'radio',
      smallTitle: showFamilyText
        ? 'How old is the person who’s going to use it?'
        : 'How old are you?',
      details: {
        options: ['Above 13', 'Below 13'],
        score: [100, 0],
      },
      defaultNextQuestion: userId
        ? 'haveYouUsedNeuroFeedback'
        : 'reasonsForDoingNeuroFeedback',
      nextQuestion: {
        'Below 13': 'haveYouUsedNeuroFeedback',
      },
      completedPercentage: 20,
    },
    reasonsForDoingNeuroFeedback: {
      id: 'reasonsForDoingNeuroFeedback',
      type: 'checkbox',
      smallTitle: showFamilyText
        ? 'What are their major reasons for wanting to do Myndlift neurofeedback training?'
        : 'What are your major reasons for wanting to do Myndlift neurofeedback training?',
      details: {
        options: [
          'Improve focus',
          'Peak performance',
          'Reduce anxiety',
          'General wellbeing',
          'Other',
        ],
        score: [100, 0, 100, 0, 100],
        optionalInputFields: ['Other'],
      },
      defaultNextQuestion: userId ? 'progress-exit' : 'howDidYouHereAboutUs',
      completedPercentage: 50,
    },
    howDidYouHereAboutUs: {
      id: 'howDidYouHereAboutUs',
      type: 'radio',
      smallTitle: 'How did you hear about us? 🤔',
      details: {
        options: [
          'Referred by a Myndlift provider',
          'Through a friend',
          'Social media',
          'Search engine (Google, Bing, etc.)',
          'Quarterback (Netflix)',
          'Podcasts',
          'Other',
        ],
        score: [0, 100, 100, 100, 100, 100, 100],
        optionalInputFields: ['Podcasts', 'Other'],
      },
      shouldHideContinueBtn: true,
      defaultNextQuestion: 'doYouOwnMuse',
      nextQuestion: {
        'Referred by a Myndlift provider': 'isProviderOfferMyndlift',
      },
      completedPercentage: 60,
    },
    doYouOwnMuse: {
      id: 'doYouOwnMuse',
      type: 'radio',
      title: 'Do you already own a Muse headband?',
      subTitle:
        'Muse is used to sense your brain activity. It’s okay if you don’t already have one.',
      details: {
        options: ['Yes', 'No'],
        score: [200, 0],
      },
      shouldHideContinueBtn: true,
      defaultNextQuestion: 'email',
      completedPercentage: 90,
    },
    email: {
      id: 'email',
      type: 'email',
      smallTitle: discountCouponAmount
        ? `Your plan is ready! Get $${discountCouponAmount} off by entering your email.`
        : 'Enter your email address to reveal your recommended training plan.',
      answerType: 'object',
      requiredAnswers: ['email'],
      additionalSubTitle:
        'By clicking “See results" you agree to receive offers and updates from Myndlift. You can unsubscribe at any time.',
      // shouldHideBackBtn: true,
      details: {
        textFields: [
          {
            inputType: 'email',
            placeholder: 'Email',
            inputName: 'email',
            required: true,
            errorMsg: 'A valid email is required',
          },
        ],
      },
      defaultNextQuestion: 'progress-exit-2',
      completedPercentage: 100,
      btnText: 'See results',
    },
    'exit-a': {
      id: 'exit-a',
      type: 'content',
      shouldHideContinueBtn: true,
      details: {
        html: (
          <>
            <h2 class="question-title">
              There’s Myndlift for clinicians. We suggest trying that!
            </h2>
            <p class="question-sub-title">
              If you’d like to use Myndlift with your clients, we suggest
              checking out our offering for mental health clinicians.
            </p>

            <a class="btn" role="button" href="https://myndlift.com/home">
              Check it out
            </a>
          </>
        ),
      },
      completedPercentage: 100,
    },
    haveYouUsedNeuroFeedback: {
      id: 'haveYouUsedNeuroFeedback',
      type: 'radio',
      smallTitle: showFamilyText
        ? 'Has your family member used neurofeedback in the past?'
        : 'Have you used neurofeedback in the past?',
      details: {
        options: ['Yes', 'No'],
      },
      defaultNextQuestion: 'reasonsForDoingNeuroFeedback',
      nextQuestion: userId
        ? {
            No:
              answers.age === 'Above 13'
                ? 'reasonsForDoingNeuroFeedback'
                : 'exit-b',
            Yes:
              answers.age === 'Above 13'
                ? 'reasonsForDoingNeuroFeedback'
                : 'headsetSizeWarning',
          }
        : {
            No: 'exit-b',
            Yes: 'headsetSizeWarning',
          },
      completedPercentage: 30,
    },
    'progress-exit': {
      id: 'progress-exit',
      type: 'content',
      shouldHideContinueBtn: true,
      shouldHideBackBtn: true,
      details: {
        html: <div />,
      },
      completedPercentage: 100,
    },
    'progress-exit-2': {
      id: 'progress-exit-2',
      type: 'content',
      shouldHideContinueBtn: true,
      shouldHideBackBtn: true,
      details: {
        html: <div />,
      },
      completedPercentage: 100,
    },
    isProviderOfferMyndlift: {
      id: 'isProviderOfferMyndlift',
      type: 'radio',
      smallTitle: 'Is your provider supervising your training with Myndlift?',
      details: {
        options: [
          'Yes, I am/will be getting Myndlift through them directly',
          'No, they just referred me to get it on my own',
          "I'm not sure",
        ],
      },
      defaultNextQuestion: 'doYouOwnMuse',
      nextQuestion: {
        'Yes, I am/will be getting Myndlift through them directly':
          'contactYourProvider',
      },
      shouldHideContinueBtn: true,
      completedPercentage: 85,
    },
    contactYourProvider: {
      id: 'contactYourProvider',
      type: 'content',
      details: {
        html: (
          <>
            <h2 class="question-title">
              Contact your provider to access Myndlift
            </h2>
            <p class="question-sub-title">
              When your provider signed you up, you should have received an
              email from Myndlift with the activation code{' '}
              <span role="img" aria-label="key">
                🔑
              </span>{' '}
              <br /> <br /> If you can't find that email, ask your provider to
              send you their unique Clinic Code. <br />
              This serves as your activation code which you can use in-app to
              link to your provider. <br />
              <br /> Contact us at{' '}
              <a
                href="mailto:support@myndlift.com"
                id="check_other_btn"
                style={{ color: '#4be0d0', marginTop: 0 }}
                target="_blank"
                rel="noopener noreferrer"
              >
                support@myndlift.com
              </a>{' '}
              if you're still having issues.
            </p>
          </>
        ),
      },
      shouldHideContinueBtn: true,
      completedPercentage: 100,
    },
    'exit-b': {
      id: 'exit-b',
      type: 'content',
      shouldHideContinueBtn: true,
      details: {
        html: (
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src="./registration-flow/emoji.svg"
                style={{ maxWidth: 25 }}
              />
            </div>
            <h2 class="question-title">
              We can’t offer {showFamilyText ? 'your family member' : 'you'}{' '}
              neurofeedback at this time.
            </h2>
            <p class="question-sub-title">
              Unfortunately, the program does not support children under the age
              of 13 that have never done neurofeedback before.
              <br />
              <span style={{ fontWeight: 'bold' }}>Good news</span>: you can
              still try to get access to Myndlift through one of our local
              providers
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 250,
                margin: '0 auto',
              }}
            >
              <a
                class="btn"
                role="button"
                id="exit-btn"
                href="https://dashboard.myndlift.com/providers-search.html"
              >
                Find a provider
              </a>
              <div
                href="/"
                id="check_other_btn"
                onClick={() => {
                  checkOtherEligibility();
                }}
                style={{ fontSize: 15, cursor: 'pointer' }}
              >
                Check eligibility for someone else
              </div>
            </div>
          </>
        ),
      },
      completedPercentage: 100,
    },
    headsetSizeWarning: {
      id: 'headsetSizeWarning',
      type: 'radio',
      title:
        'There’s a chance the headset won’t fit properly on the child’s head.',
      subTitle:
        'You can check this by measuring the head circumference or checking the fit with a Muse headset.',
      details: {
        options: [
          'Tried it with a Muse. It looks tight.',
          'Measured the head circumference, and it’s more than 21 inches (53 centimeters).',
          'Measured it and it is quite loose.',
          'Measured the head circumference and it was under 21 inches (53 centimeters).',
        ],
      },
      nextQuestion: {
        'Measured it and it is quite loose.': 'exit-d',
        'Measured the head circumference and it was under 21 inches (53 centimeters).':
          'exit-d',
      },
      defaultNextQuestion: showFamilyText
        ? 'reasonsForDoingNeuroFeedback'
        : 'howDidYouHereAboutUs',
      completedPercentage: 40,
    },
    'exit-d': {
      id: 'exit-d',
      type: 'content',
      shouldHideContinueBtn: true,
      details: {
        html: (
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src="./registration-flow/emoji.svg"
                style={{ maxWidth: 25 }}
              />
            </div>
            <h2 class="question-title">
              We can’t offer {showFamilyText ? 'your family member' : 'you'}{' '}
              neurofeedback at this time.
            </h2>
            <p class="question-sub-title">
              We’re sorry. Due to the loose fit. It seems Myndlift won’t work
              properly for you through our remote program.
              <br />
              <span style={{ fontWeight: 'bold' }}>Good news</span>: you can
              still try to get access to Myndlift through one of our local
              providers
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 250,
                margin: '0 auto',
              }}
            >
              <a
                class="btn"
                role="button"
                id="exit-btn"
                href="https://dashboard.myndlift.com/providers-search.html"
              >
                Find a provider
              </a>
              <div
                href="/"
                id="check_other_btn"
                onClick={() => {
                  checkOtherEligibility();
                }}
                style={{ fontSize: 15, cursor: 'pointer' }}
              >
                Check eligibility for someone else
              </div>
            </div>
          </>
        ),
      },
      completedPercentage: 100,
    },
  };

  const sendEvents = async (eventName, params = {}) => {
    amplitude.track(eventName, params);
    return fetch(`${funcURL}mtr-leadsFlowHelper`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventName,
        eventDetails: {
          ...params,
          eligibilityId,
          utmCampaign: utmCampaign || '',
          utmSource: utmSource || '',
        },
      }),
    });
  };

  const convertAnswerToObject = answer => {
    if (typeof answer === 'string') {
      return {
        answer_0: answer,
      };
    }

    if (typeof answer === 'object') {
      return Object.keys(answer).reduce((acc, key, index) => {
        acc[`answer_${index}`] = answer[key] === true ? key : answer[key];
        return acc;
      }, {});
    }
    return {};
  };

  const calcAnswerScore = (question, ans) => {
    if (question.type === 'radio') {
      if (question.details.score) {
        return question.details.score[
          question.details.options.findIndex(answer => answer === ans)
        ];
      }
      return 0;
    }

    if (question.type === 'checkbox') {
      if (question.details.score) {
        let score = 0;
        answers[currentQuestion].forEach(
          // eslint-disable-next-line no-return-assign
          answer =>
            (score +=
              question.details.score[
                question.details.options.findIndex(
                  optionalAnswer => answer === optionalAnswer
                )
              ] || 0)
        );
        return score;
      }
      return 0;
    }
    if (question.id === 'email') {
      return 200;
    }
    return 0;
  };

  const calcExtraInput = option => {
    if (questions[currentQuestion].details.requiredInputFields) {
      if (
        questions[currentQuestion].details.requiredInputFields.find(
          el => el === option
        ) &&
        answers[currentQuestion] &&
        answers[currentQuestion].includes(option)
      ) {
        return true;
      }
    }
    if (questions[currentQuestion].details.optionalInputFields) {
      if (
        questions[currentQuestion].details.optionalInputFields.find(
          el => el === option
        ) &&
        answers[currentQuestion] &&
        answers[currentQuestion].includes(option)
      ) {
        return true;
      }
    }

    return false;
  };

  const calcExtraInputCheckbox = option => {
    if (questions[currentQuestion].details.requiredInputFields) {
      if (
        answers[currentQuestion] &&
        answers[currentQuestion].find(inputAnswer =>
          inputAnswer.includes(option)
        ) &&
        questions[currentQuestion].details.requiredInputFields.find(
          inputAnswer => inputAnswer === option
        )
      ) {
        return true;
      }
    }
    if (questions[currentQuestion].details.optionalInputFields) {
      if (
        answers[currentQuestion] &&
        answers[currentQuestion].find(inputAnswer =>
          inputAnswer.includes(option)
        ) &&
        questions[currentQuestion].details.optionalInputFields.find(
          inputAnswer => inputAnswer === option
        )
      ) {
        return true;
      }
    }
    return false;
  };

  const calcExtraInputContinueBtn = () => {
    if (questions[currentQuestion].details.requiredInputFields) {
      if (
        questions[currentQuestion].details.requiredInputFields.find(
          el =>
            answers[currentQuestion] && answers[currentQuestion].includes(el)
        )
      ) {
        return 'required';
      }
    }
    if (questions[currentQuestion].details.optionalInputFields) {
      if (
        questions[currentQuestion].details.optionalInputFields.find(
          el =>
            answers[currentQuestion] && answers[currentQuestion].includes(el)
        )
      ) {
        return 'optional';
      }
    }

    return false;
  };

  const goToPaymentPage = async (didEliibilty = false) => {
    const email = emailInput || false;
    const urlVariables = new URLSearchParams(window.location.search);
    const groupIdUrl = urlVariables.get('groupId');
    urlVariables.delete('userId');
    if (deepLinkCouponCode) {
      urlVariables.append('couponCode', deepLinkCouponCode);
    }
    if (didEliibilty) {
      urlVariables.append('quiz', true);
    }
    if (groupId && !groupIdUrl) {
      urlVariables.append('groupId', groupId);
    }
    if (email) {
      urlVariables.append('email', email);
    }

    if (userId) {
      urlVariables.append('optionalUserId', userId);
      urlVariables.append('userId', userId);
    }
    if (didEliibilty) {
      // eslint-disable-next-line no-unused-expressions
      document.getElementById('react-registration-flow')
        ? (document.getElementById('react-registration-flow').style.display =
            'none')
        : null;
      await sendEvents('eligibility_go_to_payment_page', {
        ...answers,
      });
    }

    ga('send', {
      hitType: 'event',
      eventCategory: 'MTR',
      eventAction: 'PassedEligibility',
      eventLabel: answers.howDidYouHereAboutUs,
      userDefinedValue: 100,
      eventValue: 100,
    });
    fbq('trackCustom', 'PassedEligibility', { score: 100 });

    const newWindow = (() => {
      if (isOldBilling) {
        return window.open(`./payment-page.html?${urlVariables}`, '_self');
      }
      return window.open(`./subscription?${urlVariables}`, '_self');
    })();

    sessionStorage.questions = JSON.stringify(prevQuestions);
    sessionStorage.eligibilityId = eligibilityId;
    sessionStorage.prettyQuestions = JSON.stringify(answers);
    newWindow.sessionStorage = sessionStorage;
  };

  const onBack = () => {
    setCurrentSlideIsLeft(false);
    const copy2 = [...prevQuestions];
    const backQuestion = copy2.pop();
    setPrevQuestions(copy2);
    setCurrentQuestion(backQuestion);
    sendEvents('eligibility_on_back');
  };

  const onContinueOtherClicked = () => {
    if (calcExtraInputContinueBtn() === 'required' && !otherInput) {
      return;
    }
    const copy = { ...answers };
    const currentAnswer = answers[currentQuestion];
    let answerToWrite;
    if (currentAnswer.indexOf(' - ') !== -1) {
      answerToWrite = currentAnswer.slice(0, currentAnswer.indexOf(' - '));
    } else {
      answerToWrite = currentAnswer;
    }
    copy[currentQuestion] = `${answerToWrite} - ${otherInput}`;
    setAnswers(copy);
    setCurrentSlideIsLeft(true);
    const copy2 = [...prevQuestions];
    copy2.push(currentQuestion);
    setPrevQuestions(copy2);
    setCurrentQuestion('doYouOwnMuse');
    sendEvents('eligibility_question', {
      questionId: questions[currentQuestion].id,
      ...convertAnswerToObject(answers[currentQuestion]),
      score: 100,
      data: otherInput,
    });
    ga('send', {
      hitType: 'event',
      eventCategory: 'MTR',
      eventAction: 'eligibilityQuestion',
      eventLabel: questions[currentQuestion].id,
      eventValue: calcAnswerScore(questions[currentQuestion])
        ? calcAnswerScore(questions[currentQuestion])
        : 0,
      userDefinedValue: calcAnswerScore(questions[currentQuestion])
        ? calcAnswerScore(questions[currentQuestion])
        : 0,
    });
  };

  const onRadioOptionSelected = selectedOption => {
    setOtherInput('');
    fbq('trackCustom', questions[currentQuestion].id, {
      score: calcAnswerScore(questions[currentQuestion]),
      value: calcAnswerScore(questions[currentQuestion]),
      answer: JSON.stringify(questions[currentQuestion].answer),
    });
    if (
      (questions[currentQuestion].details.requiredInputFields &&
        questions[currentQuestion].details.requiredInputFields.find(
          el => el === selectedOption
        )) ||
      (questions[currentQuestion].details.optionalInputFields &&
        questions[currentQuestion].details.optionalInputFields.find(
          el => el === selectedOption
        ))
    ) {
      if (answers[currentQuestion] === selectedOption) {
        const copy = { ...answers };
        copy[currentQuestion] = null;
        setAnswers(copy);
        return;
      }
      const copy = { ...answers };
      copy[currentQuestion] = selectedOption;
      setAnswers(copy);
      sendEvents('eligibility_question', {
        action: `${selectedOption}_clicked`,
        data: otherInput,
        questionId: questions[currentQuestion].id,
      });
      setTimeout(() => {
        radioboxInputRef.current.focus();
      }, 100);
      return;
    }
    setCurrentSlideIsLeft(true);
    const copy = { ...answers };
    copy[currentQuestion] = selectedOption;
    setAnswers(copy);

    const copy2 = [...prevQuestions];
    copy2.push(currentQuestion);
    setPrevQuestions(copy2);

    sendEvents('eligibility_question', {
      questionId: questions[currentQuestion].id,
      ...convertAnswerToObject(copy[currentQuestion]),
      score: calcAnswerScore(questions[currentQuestion], selectedOption),
    });
    ga('send', {
      hitType: 'event',
      eventCategory: 'MTR',
      eventAction: 'eligibilityQuestion',
      eventLabel: questions[currentQuestion].id,
      eventValue: calcAnswerScore(questions[currentQuestion], selectedOption)
        ? calcAnswerScore(questions[currentQuestion], selectedOption)
        : 0,
      userDefinedValue: calcAnswerScore(
        questions[currentQuestion],
        selectedOption
      )
        ? calcAnswerScore(questions[currentQuestion], selectedOption)
        : 0,
    });

    if (
      questions[currentQuestion].nextQuestion &&
      questions[currentQuestion].nextQuestion[selectedOption]
    ) {
      setCurrentQuestion(
        questions[currentQuestion].nextQuestion[selectedOption]
      );
    } else {
      setCurrentQuestion(questions[currentQuestion].defaultNextQuestion);
    }
  };

  const onCheckboxOptionSelected = selectedOption => {
    const copy = { ...answers };
    if (copy[currentQuestion]) {
      const optionIndex = copy[currentQuestion].findIndex(ans =>
        ans.includes(selectedOption)
      );
      if (optionIndex !== -1) {
        copy[currentQuestion].splice(optionIndex, 1);
      } else {
        copy[currentQuestion].push(selectedOption);
      }
    } else {
      copy[currentQuestion] = [selectedOption];
    }
    setAnswers(copy);

    if (
      questions[currentQuestion].details.requiredInputFields ||
      questions[currentQuestion].details.optionalInputFields
    ) {
      // eslint-disable-next-line no-unused-expressions
      questions[currentQuestion].details.optionalInputFields &&
        questions[currentQuestion].details.optionalInputFields.forEach(
          field => {
            if (field === selectedOption) {
              setTimeout(() => {
                checkboxInputRef.current.focus();
              }, 100);
              sendEvents('eligibility_question', {
                action: 'other_clicked',
                data: otherInput,
                questionId: questions[currentQuestion].id,
              });
            }
          }
        );
      // eslint-disable-next-line no-unused-expressions
      questions[currentQuestion].details.requiredInputFields &&
        questions[currentQuestion].details.requiredInputFields.forEach(
          field => {
            if (field === selectedOption) {
              sendEvents('eligibility_question', {
                action: 'other_clicked',
                data: otherInput,
                questionId: questions[currentQuestion].id,
              });
            }
          }
        );
    }
  };

  const isContinueBtnDisabled = () => {
    if (questions[currentQuestion].type === 'checkbox') {
      return !(answers[currentQuestion] && answers[currentQuestion].length);
    }
    if (questions[currentQuestion].type === 'email') {
      return !isValidEmail(emailInput);
    }
    return false;
  };

  const onContinueClicked = () => {
    if (isContinueBtnDisabled()) {
      return;
    }
    if (currentQuestion === 'email') {
      fbq('track', 'Lead');
      answers.email = emailInput;
      goToPaymentPage(true);
    }
    fbq('trackCustom', questions[currentQuestion].id, {
      score: calcAnswerScore(questions[currentQuestion]),
      value: calcAnswerScore(questions[currentQuestion]),
      answer: JSON.stringify(questions[currentQuestion].answer),
    });
    setCurrentSlideIsLeft(true);
    const copy2 = [...prevQuestions];
    copy2.push(currentQuestion);
    setPrevQuestions(copy2);
    if (
      questions[currentQuestion].type === 'checkbox' &&
      (questions[currentQuestion].details.requiredInputFields ||
        questions[currentQuestion].details.optionalInputFields)
    ) {
      if (questions[currentQuestion].details.requiredInputFields) {
        questions[currentQuestion].details.requiredInputFields.forEach(
          field => {
            answers[currentQuestion].forEach((ans, i) => {
              if (ans.includes(field)) {
                const currentAnswer = answers[currentQuestion][i];
                let answerToWrite;
                if (currentAnswer.indexOf(' - ') !== -1) {
                  answerToWrite = currentAnswer.slice(
                    0,
                    currentAnswer.indexOf(' - ')
                  );
                } else {
                  answerToWrite = currentAnswer;
                }
                answers[currentQuestion][
                  i
                ] = `${answerToWrite} - ${otherInput}`;
              }
            });
          }
        );
      }
      if (questions[currentQuestion].details.optionalInputFields) {
        questions[currentQuestion].details.optionalInputFields.forEach(
          field => {
            answers[currentQuestion].forEach((ans, i) => {
              if (ans.includes(field)) {
                const currentAnswer = answers[currentQuestion][i];
                let answerToWrite;
                if (currentAnswer.indexOf(' - ') !== -1) {
                  answerToWrite = currentAnswer.slice(
                    0,
                    currentAnswer.indexOf(' - ')
                  );
                } else {
                  answerToWrite = currentAnswer;
                }
                answers[currentQuestion][
                  i
                ] = `${answerToWrite} - ${otherInput}`;
              }
            });
          }
        );
      }
    }
    setOtherInput('');
    setCurrentQuestion(questions[currentQuestion].defaultNextQuestion);

    sendEvents('eligibility_question', {
      questionId: questions[currentQuestion].id,
      ...convertAnswerToObject(answers[currentQuestion]),
      score:
        calcAnswerScore(questions[currentQuestion], answers[currentQuestion]) ||
        0,
    });
    ga('send', {
      hitType: 'event',
      eventCategory: 'MTR',
      eventAction: 'eligibilityQuestion',
      eventLabel: questions[currentQuestion].id,
      eventValue: calcAnswerScore(questions[currentQuestion])
        ? calcAnswerScore(questions[currentQuestion])
        : 0,
      userDefinedValue: calcAnswerScore(questions[currentQuestion])
        ? calcAnswerScore(questions[currentQuestion])
        : 0,
    });
  };

  const onEmailChange = val => {
    setEmailInput(val);
  };

  const onOtherTextChange = val => {
    setEventTimeout(val);

    setOtherInput(val);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (eventTimeout) {
      const delayDebounceFn = setTimeout(() => {
        sendEvents('eligibility_question', {
          questionId: questions[currentQuestion].id,
          action: 'other_input_changed',
          data: otherInput,
        });
      }, 3000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [eventTimeout]);

  useEffect(() => {
    (async () => {
      if (!userId) {
        // onComponentReadyToRender();
        setIsComponentReadyToRender(true);
      } else {
        document.getElementById('loader-container').style.display = 'flex';
        const isOldBilling1 =
          userId &&
          (await database
            .ref(`remoteAdminUsers/${userId}/isOldBilling`)
            .once('value')
            .then(snapshot => snapshot.val()));
        setIsOldBilling(isOldBilling1);

        const familyPrice1 =
          isOldBilling &&
          (await database
            .ref('remoteUsersConfig/bundles/bundleForFamily/price')
            .once('value')
            .then(snapshot => snapshot.val()));
        setFamilyPrice(familyPrice1);

        // const priceWithoutHeadset =
        //   isOldBilling &&
        //   (await database
        //     .ref('remoteUsersConfig/bundles/bundleWithoutHeadset/price')
        //     .once('value')
        //     .then(snapshot => snapshot.val()));
        // setPriceWithoutHeadset(priceWithoutHeadset)

        let groupId1 = await database
          .ref(`/remoteBilling/billingConfigs/${userId}/allowedGroupId`)
          .once('value')
          .then(snapshot => snapshot.val());

        if (!groupId1) {
          groupId1 = await database
            .ref('/config/remoteDefaultGroupId')
            .once('value')
            .then(snapshot => snapshot.val());
        }

        const plan = await database
          .ref(`remoteBilling/customPlans/${groupId1}`)
          .once('value')
          .then(snapshot => snapshot.val());
        if (!plan) {
          window.open('404.html', '_self');
        }

        const newPlansCheapestPriceForFamily1 = Math.min(
          ...plan.familyMemberPlans.planOptions.map(o =>
            Math.round(o.price / o.billingPeriodInMonths)
          )
        );
        setNewPlansCheapestPriceForFamily(newPlansCheapestPriceForFamily1);

        onComponentReadyToRender();
        setIsComponentReadyToRender(true);

        try {
          const msg = 'finishLoading';
          if (window.Android && window.Android.receiveMessageFromJS) {
            window.Android.receiveMessageFromJS(msg);
          }
          if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers[msg] &&
            window.webkit.messageHandlers[msg].postMessage
          ) {
            window.webkit.messageHandlers[msg].postMessage(msg);
          }
        } catch (error) {
          throw error;
          // defensiveThrow({ error });
        }

        // const defaultGroupId = await database
        //   .ref('config/remoteDefaultGroupId')
        //   .once('value')
        //   .then(snapshot => snapshot.val());

        // const discountCoupon = await database
        //   .ref(`remoteUsersConfig/discountCoupon/${groupId || defaultGroupId}`)
        //   .once('value')
        //   .then(snapshot => snapshot.val());

        // const remotePlans = await database
        //   .ref(`remoteBilling/customPlans/${defaultGroupId}`)
        //   .once('value')
        //   .then(snapshot => snapshot.val());

        // const registrationBannerText = await database
        //   .ref('remoteUsersConfig/bannerText/registrationFlow')
        //   .once('value')
        //   .then(snapshot => snapshot.val());
      }
    })();
    ga('send', {
      hitType: 'event',
      eventCategory: 'MTR',
      eventAction: 'StartedEligibility',
      eventLabel: 'StartedEligibility',
      userDefinedValue: 100,
      eventValue: 100,
    });
    fbq('trackCustom', 'StartedEligibility', { score: 100 });
    if (userId) {
      const AddFamilyMemberClickedEventLabel =
        (utmSource === 'dashboard' && 'Upon Signup') ||
        (utmSource === 'initial_email' && 'FromLink') ||
        (utmSource === 'iOSapp' && 'iosApp') ||
        (utmSource === 'androidApp' && 'androidApp') ||
        'androidApp';

      ga('send', {
        hitType: 'event',
        eventCategory: 'MTR',
        eventAction: 'AddFamilyMemberClicked',
        eventLabel: AddFamilyMemberClickedEventLabel,
      });
    }
  }, []);

  useEffect(() => {
    setCurrentSlideIsLeft(!currentSlideIsLeft);
    setCurrentSlideIsLeft(!currentSlideIsLeft);
  }, [currentQuestion]);

  useEffect(() => {
    if (questions[currentQuestion].id === 'progress-exit') {
      goToPaymentPage(true);
    }
    if (questions[currentQuestion].id.includes('exit-')) {
      ga('send', {
        hitType: 'event',
        eventCategory: 'MTR',
        eventAction: 'FailedEligibility',
        eventLabel: 'FailedEligibility',
      });
    }
  }, [currentQuestion]);

  useEffect(() => {
    if (
      answers[currentQuestion] &&
      (questions[currentQuestion].details.requiredInputFields ||
        questions[currentQuestion].details.optionalInputFields)
    ) {
      if (questions[currentQuestion].details.requiredInputFields) {
        questions[currentQuestion].details.requiredInputFields.forEach(
          field => {
            if (questions[currentQuestion].type === 'checkbox') {
              answers[currentQuestion].forEach(ans => {
                if (ans.includes(field)) {
                  setOtherInput(
                    ans.indexOf(' - ') === -1
                      ? ''
                      : ans.substring(ans.indexOf(' - ') + 2, ans.length)
                  );
                }
              });
            }
            if (questions[currentQuestion].type === 'radio') {
              if (answers[currentQuestion].includes(field)) {
                setOtherInput(
                  answers[currentQuestion].indexOf(' - ') === -1
                    ? ''
                    : answers[currentQuestion].substring(
                        answers[currentQuestion].indexOf(' - ') + 2,
                        answers[currentQuestion].length
                      )
                );
              }
            }
          }
        );
      }
      if (questions[currentQuestion].details.optionalInputFields) {
        questions[currentQuestion].details.optionalInputFields.forEach(
          field => {
            if (questions[currentQuestion].type === 'checkbox') {
              answers[currentQuestion].forEach(ans => {
                if (ans.includes(field)) {
                  setOtherInput(
                    ans.indexOf(' - ') === -1
                      ? ''
                      : ans.substring(ans.indexOf(' - ') + 3, ans.length)
                  );
                }
              });
            }
            if (questions[currentQuestion].type === 'radio') {
              if (answers[currentQuestion].includes(field)) {
                setOtherInput(
                  answers[currentQuestion].indexOf(' - ') === -1
                    ? ''
                    : answers[currentQuestion].substring(
                        answers[currentQuestion].indexOf(' - ') + 3,
                        answers[currentQuestion].length
                      )
                );
              }
            }
          }
        );
      }
    }
  }, [currentQuestion]);

  // const getCouponDiscount = async coupon => {
  //   const discountCouponResponse = await window.fetch(
  //     `${funcURL}shop-myndliftCouponDiscount`,
  //     {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json; charset=utf-8',
  //       },
  //       body: JSON.stringify({
  //         couponCode: coupon,
  //       }),
  //     }
  //   );

  //   const discountCouponResponseData = await discountCouponResponse.json();

  //   const discountAmount =
  //     discountCouponResponseData && discountCouponResponseData.result === true
  //       ? discountCouponResponseData.discountAmount
  //       : 0;

  //   return discountAmount;
  // };

  // useEffect(() => {
  //   (async () => {
  //     if (userId || !remoteConfig) {
  //       return;
  //     }
  //     const identifyEvent2 = new amplitude.Identify();
  //     // eslint-disable-next-line no-undef
  //     remoteConfig
  //       .fetchAndActivate()
  //       .then(() => {
  //         const remoteConfigValue = window.remoteConfig.getValue(
  //           AB_TESTING_ELIGIBILITY_PARAM_NAME
  //         );
  //         // console.log(remoteConfigValue._value);
  //         // eslint-disable-next-line no-underscore-dangle
  //         const abVal = JSON.parse(remoteConfigValue._value);
  //         setABTestimonialToggle(abVal);

  //         // // eslint-disable-next-line no-undef
  //         // ga('set', 'dimension2', abVal ? 'B' : 'A');
  //         // // setWasRemoteConfigValuesFetched(true);
  //         // // eslint-disable-next-line no-undef
  //         // localStorage.setItem('ABToggleEligibility', abVal);
  //         // // eslint-disable-next-line no-undef
  //         // amplitude.track('got_variant_from_remote_config_eligibility', {
  //         //   abVal: abVal ? 'B' : 'A',
  //         // });
  //         // console.log('remote config');
  //         // console.log('selected variant: ', abVal ? 'B' : 'A');
  //         identifyEvent2.set(
  //           'eligibilty_more_reviews_varient',
  //           abVal ? 'B' : 'A'
  //         );

  //         // eslint-disable-next-line no-undef
  //         amplitude.identify(identifyEvent2);
  //       })
  //       // eslint-disable-next-line no-unused-vars
  //       .catch(err => {
  //         // console.log(err);
  //         // setWasRemoteConfigValuesFetched(true);
  //         setABTestimonialToggle(false);

  //         // eslint-disable-next-line no-undef
  //         // amplitude.track(
  //         //   'got_variant_manually_error_from_remote_config_elgibility',
  //         //   {
  //         //     abVal: 'A',
  //         //   }
  //         // );
  //       });

  //     //   const identifyEvent3 = new amplitude.Identify();
  //     //   let discountAmount;
  //     //   if (couponCodeURL) {
  //     //     discountAmount = await getCouponDiscount(couponCodeURL);
  //     //     setDiscountCouponAmount(discountAmount);
  //     //     if (discountAmount) {
  //     //       identifyEvent3.set(
  //     //         'eligibility_coupon_test_by_coupon',
  //     //         couponCodeURL
  //     //       );
  //     //     }
  //     //   }

  //     //   if (!discountAmount) {
  //     //     const defaultGroupId = await database
  //     //       .ref('config/remoteDefaultGroupId')
  //     //       .once('value')
  //     //       .then(snapshot => snapshot.val());

  //     //     const discountCoupon = await database
  //     //       .ref(`remoteUsersConfig/discountCoupon/${defaultGroupId}`)
  //     //       .once('value')
  //     //       .then(snapshot => snapshot.val());

  //     //     if (discountCoupon) {
  //     //       discountAmount = await getCouponDiscount(discountCoupon);
  //     //       setDiscountCouponAmount(discountAmount);
  //     //     } else {
  //     //       setDiscountCouponAmount(0);
  //     //     }

  //     //     identifyEvent3.set('eligibility_coupon_test_by_coupon', discountCoupon);

  //     //     // eslint-disable-next-line no-undef
  //     //     amplitude.identify(identifyEvent3);
  //     //   }
  //   })();
  //   // eslint-disable-next-line no-undef
  // }, [remoteConfig]);

  const renderRadio = () => {
    return (
      <div className={styles.question_answers_container}>
        {questions[currentQuestion].details.options.map(option => {
          return (
            <div
              id={option}
              className={styles.question_answer_item_container}
              key={option}
            >
              <div
                className={styles.question_answer_item}
                style={
                  answers[currentQuestion] &&
                  answers[currentQuestion].includes(option)
                    ? { border: '2px solid #4be0d0' }
                    : null
                }
                onClick={() => onRadioOptionSelected(option)}
              >
                {option}
              </div>
              {calcExtraInput(option) ? (
                <input
                  type="text"
                  id="other_1"
                  ref={radioboxInputRef}
                  name="other"
                  onChange={e => {
                    onOtherTextChange(e.target.value);
                  }}
                  onFocus={() => {
                    window.scrollTo(0, document.body.scrollHeight);
                  }}
                  value={otherInput}
                  className={styles.email_input}
                  placeholder="Please specify"
                  style={{ marginLeft: 10 }}
                />
              ) : null}
            </div>
          );
        })}
        {calcExtraInputContinueBtn() && (
          <>
            <div
              onClick={onContinueOtherClicked}
              className={styles.continue_btn}
              style={
                calcExtraInputContinueBtn() === 'required' && !otherInput
                  ? { backgroundColor: '#c1b9b9', cursor: 'auto' }
                  : null
              }
            >
              Continue
            </div>
          </>
        )}
      </div>
    );
  };

  const renderCheckbox = () => {
    return (
      <div className={styles.question_answers_container}>
        {questions[currentQuestion].details.options.map(option => {
          return (
            <div
              id={option}
              className={styles.question_answer_item_container}
              key={option}
            >
              <div
                onClick={() => onCheckboxOptionSelected(option)}
                className={styles.question_answer_item}
                style={
                  answers[currentQuestion] &&
                  answers[currentQuestion].findIndex(ans =>
                    ans.includes(option)
                  ) !== -1
                    ? { border: '2px solid #4be0d0' }
                    : null
                }
              >
                {option}
              </div>
              {calcExtraInputCheckbox(option) ? (
                <input
                  type="text"
                  id="other_2"
                  ref={checkboxInputRef}
                  name="other"
                  onChange={e => {
                    onOtherTextChange(e.target.value);
                  }}
                  onFocus={() => {
                    window.scrollTo(0, document.body.scrollHeight);
                  }}
                  value={otherInput}
                  className={styles.email_input}
                  placeholder="Please specify"
                  style={{ marginLeft: 10 }}
                />
              ) : null}
            </div>
          );
        })}
      </div>
    );
  };

  const renderEmail = () => {
    return (
      <div
      // style={{ display: 'flex', flexDirection: 'column' }}
      >
        {questions[currentQuestion].details.textFields ? (
          <div>
            <input
              type="email"
              id="email"
              name="email"
              onChange={e => {
                onEmailChange(e.target.value);
              }}
              value={emailInput}
              className={styles.email_input}
              placeholder="Email"
            />
          </div>
        ) : null}
      </div>
    );
  };

  const renderContent = () => {
    return <div>{questions[currentQuestion].details.html}</div>;
  };

  const renderQuestion = () => {
    return (
      <div
        className={styles.question_container}
        // className={`${styles.question_container} ${
        //   ABTestimonialToggle && currentQuestion === 'email'
        //     ? styles.question_container_email_step_custom_styles
        //     : ''
        // }`}
        style={
          currentQuestion === 'forWho'
            ? { top: -40, position: 'relative' }
            : null
        }
      >
        {/* <div
          className={
            ABTestimonialToggle && currentQuestion === 'email'
              ? styles.email_step_sub_container_left
              : ''
          }
        > */}
        {questions[currentQuestion].title && (
          <h2 className={styles.question_title}>
            {questions[currentQuestion].title}
          </h2>
        )}
        {questions[currentQuestion].subTitle && (
          <div className={styles.question_subtitle}>
            {questions[currentQuestion].subTitle}
          </div>
        )}
        {questions[currentQuestion].smallTitle && (
          <div
            className={styles.question_smallTitle}
            // style={
            //   ABTestimonialToggle && currentQuestion === 'email'
            //     ? { fontWeight: 'bold' }
            //     : null
            // }
          >
            {questions[currentQuestion].smallTitle}
          </div>
        )}
        <div>
          {questions[currentQuestion].type === 'radio' && renderRadio()}
          {questions[currentQuestion].type === 'checkbox' && renderCheckbox()}
          {questions[currentQuestion].type === 'email' && renderEmail()}
          {questions[currentQuestion].type === 'content' && renderContent()}
        </div>
        {questions[currentQuestion].type !== 'radio' &&
        !questions[currentQuestion].shouldHideContinueBtn ? (
          <div
            onClick={onContinueClicked}
            className={styles.continue_btn}
            style={
              isContinueBtnDisabled()
                ? { backgroundColor: '#c1b9b9', cursor: 'auto' }
                : null
            }
          >
            {questions[currentQuestion].btnText
              ? questions[currentQuestion].btnText
              : 'Continue'}
          </div>
        ) : null}
        {questions[currentQuestion].additionalSubTitle && (
          <div className={styles.question_additionalSubTitle}>
            {questions[currentQuestion].additionalSubTitle}
          </div>
        )}
        {/* </div> */}
        {/* {ABTestimonialToggle && questions[currentQuestion].type === 'email' && (
          <div className={styles.email_step_sub_container_right}>
            <TestimonialsCustomSwiper
              videoTestimonials={videoTestimonials}
              sendEvents={sendEvents}
            />
          </div>
        )} */}
      </div>
    );
  };

  const currentAnimationClass = (() => {
    if (!currentSlideIsLeft) {
      return styles.slide_in_right;
    }
    return styles.slide_in_left;
  })();

  if (!isComponentReadyToRender) {
    return null;
  }

  const isUserDidEligibilty = JSON.parse(
    localStorage.getItem('isUserDidEligibilty')
  );
  if (isUserDidEligibilty) {
    // if (!(window.Android && window.Android.receiveMessageFromJS)) {
    //   window.location.replace(window.location.href);
    // }
    goToPaymentPage();
    return null;
  }

  return (
    <div className={styles.root}>
      <span
        className={styles.back_btn}
        onClick={onBack}
        style={!prevQuestions.length ? { visibility: 'hidden' } : null}
      >
        Back
      </span>
      <div className={styles.logo_container}>
        <img src={Logo} style={{ width: 128, height: 44 }} />
      </div>
      <div className={styles.prgress_bar}>
        <div
          className={styles.prgress_bar_left}
          style={{
            width: `${questions[currentQuestion].completedPercentage}%`,
          }}
        />
        <div
          className={styles.prgress_bar_right}
          style={{
            width: `${100 - questions[currentQuestion].completedPercentage}%`,
          }}
        />
      </div>
      <div className={currentAnimationClass} style={{ height: '100%' }}>
        {renderQuestion()}
      </div>
    </div>
  );
};

RegistrationFlowPageBase.propTypes = exact({
  onComponentReadyToRender: PropTypes.func.isRequired,
  deepLinkCouponCode: PropTypes.string,
});

export const RegistrationFlowPage = React.memo(RegistrationFlowPageBase);
RegistrationFlowPage.displayName = 'RegistrationFlowPage';
