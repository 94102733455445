import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import nth from 'lodash/nth';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import countryTelData from 'country-telephone-data';
import exact from 'prop-types-exact';
import classNames from 'classnames';
// import Switch from 'react-switch';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Backdrop,
  CircularProgress,
  Checkbox,
  Dialog,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CheckIcon from '@material-ui/icons/Check';
import RefreshIcon from '@material-ui/icons/Refresh';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
// import InfoIcon from '@material-ui/icons/Info';
import { injectStripe, CardElement } from 'react-stripe-elements';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useHistory } from 'react-router-dom';

import dayJS from 'dayjs';
import styles from './MTRSubscriptionPage.scss';
import { Stepper } from './Stepper/Stepper';
// import logoNoTextImage from '../../../../myndlift-signup/public/assets/logo-no-text.jpg';
// import logoOnlyTextImage from '../../../../myndlift-signup/public/assets/logo-only-text.jpg';
import packageWithoutMuseImage from '../../../../myndlift-signup/public/assets/mtr-nomuse.png';
import packageWithMuseImage from '../../../../myndlift-signup/public/assets/mtr-muse.png';
import selectPlanMuse from '../../../../myndlift-signup/public/assets/muse_select_plan.png';
import womanPracticeImage from '../../../../myndlift-signup/public/assets/woman_practice_large.png';
import womanPracticeMobileImage from '../../../../myndlift-signup/public/assets/hero-image.png';
import womanPracticeMobileImage1 from '../../../../myndlift-signup/public/assets/woman_practice_ipad_mini.png';
import womanPracticeTabletImage from '../../../../myndlift-signup/public/assets/woman_practice_tablet.png';
import nerdWithHeadsetImage from '../../../../myndlift-signup/public/assets/nerd_with_headset.png';
import nerdWithHeadsetMobileImage from '../../../../myndlift-signup/public/assets/nerd_with_headset_mobile.png';
import nerdWithHeadsetTabletImage from '../../../../myndlift-signup/public/assets/nerd_with_headset_tablet.png';
import PlayImage from '../../../../myndlift-signup/public/assets/play-button-blue.png';
import logoImage from '../../../../myndlift-signup/public/assets/modern_logo_black.png';
// import smallLogoImage from '../../../../myndlift-signup/public/assets/small_logo.png';
// import patrickReviewImage from '../../../../myndlift-signup/public/assets/julian_review.png';
// import julianReviewImage from '../../../../myndlift-signup/public/assets/patrick_review.png';
import {
  removeAllAfterLastChar,
  isValidEmail,
  isValidPhone,
  isValidZipCode,
  scrollTo,
  getCurrentURLParams,
  getEventLogger,
  postMessageToApp,
  postToFunctions,
  countryFlags,
  triggerShareASaleService,
} from '../../utils/utils';
import { FAQS } from './FAQS/FAQS';
import {
  getPlanTypeByBillingPeriodInMonths,
  getPlanPriceByWeek,
  getPlanSaving,
  getPlanSavingPercentage,
} from '../../models/mtr-subscription/mtr-subscription';
import {
  usaStates,
  canadaProvinces,
  countriesJSON,
} from '../../models/mtr-subscription/countriesData';
import { usePreviousValue } from '../../Core/hooks/usePreviousValue';
import { TooltipWrapper } from '../../Core/Components/TooltipWrapper/TooltipWrapper';
import { TooltipTitle } from '../../Core/Components/TooltipTitle/TooltipTitle';
import { useMediaMatch } from '../../Core/hooks/useMediaMatch';
import {
  mdMediaMaxWidth,
  xsMediaMaxWidth,
  xsExtraMediaMaxWidth,
} from '../../cssInJs/constants';
import { FailedPaymentDialog } from './FailedPaymentDialog/FailedPaymentDialog';
import { MuseDialog } from './MuseDialog/MuseDialog';
import { AccountReactivatedSuccessfullyDialog } from './AccountReactivatedSuccessfullyDialog/AccountReactivatedSuccessfullyDialog';
import { CouponDiscountBanner } from './CouponDiscountBanner/CouponDiscountBanner';
import { NonfPackagePlan } from '../../Components/NonfPackagePlan/NonfPackagePlan';
import { MoneyIcon } from '../../Core/Icons/MoneyIcon';
import { ShippingIcon } from '../../Core/Icons/ShippingIcon';
import { StarsIcon } from '../../Core/Icons/StarsIcon';
import { WhiteMoneyIcon } from '../../Core/Icons/WhiteMoneyIcon';
import { StepsIcon } from '../../Core/Icons/StepsIcon';
import { useFirebaseDB } from '../../Core/hooks/useFirebaseDB';
import { ReviewsCarousel } from '../MTRManageSubscriptionPage/ReviewsCarousel/ReviewsCarousel';

const environmentConfig =
  window.environmentConfig[window.environmentConfig.current];

// eslint-disable-next-line no-restricted-globals, no-undef
const isProd = !(environmentConfig.isDev || location.hostname === 'localhost');

const packagePlansColors = ['#ABA5FC', '#AB7EE8'];

const countriesDataList = cloneDeep(countryTelData).allCountries.map(
  country => {
    const newCountry = cloneDeep(country);
    newCountry.name = removeAllAfterLastChar(country.name, '(').trim();
    newCountry.iso2 = country.iso2;
    return newCountry;
  }
);

const stripeElementStyle = {
  base: {
    color: '#585858',
  },
};

const stepperSteps = {
  choosePlan: 'Select plan',
  shippingInfo: 'Shipping info',
  cart: 'Cart',
  payment: 'Payment',
  paymentB: 'Payment ',
  shippingB: 'Shipping',
};

const choosePlanSteps = {
  museOptions: 'museOptions',
  paymentOptions: 'paymentOptions',
};

const museOptions = {
  iAlreadyOwnAMuseDevice: 'iAlreadyOwnAMuseDevice',
  iWillBuyMuseMyself: 'iWillBuyMuseMyself',
  includeAMuseDevice: 'includeAMuseDevice',
};

const museOptionsWithoutHeadset = {
  iAlreadyOwnAMuseDevice: 'iAlreadyOwnAMuseDevice',
  iWillBuyMuseMyself: 'iWillBuyMuseMyself',
};

const shippingFormRequiredFormattedFieldNames = {
  firstName: 'First name',
  lastName: 'Last name',
  city: 'City',
  street: 'Street, P.O. Box, etc…',
  zipCode: 'Zip Code',
  phone: 'Phone',
  email: 'Email',
  state: 'State',
};

const paymentFormRequiredFormattedFieldNames = {
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
};

const newPaymentFormRequiredFormattedFieldNames = {
  nameOnCard: 'Name on card',
};

const planTypes = {
  corePlans: 'corePlans',
  continuityPlans: 'continuityPlans',
  familyMemberPlans: 'familyMemberPlans',
};

const formattedPlanTypes = {
  [planTypes.corePlans]: 'Subscription',
  [planTypes.continuityPlans]: 'Continuity Training',
  [planTypes.familyMemberPlans]: 'Core Training (Family member)',
};

const initialCouponDiscount = {
  type: 'Const',
  amount: 0,
};

const logMTRSubscriptionAppEvent = getEventLogger({
  prefix: 'mtr_subscription_app',
});

const MTRSubscriptionPageBase = ({
  faqs,
  stripe,
  nodeURL,
  plans,
  groupId,
  museDevicePrice,
  discountCouponAmount,
  discountCoupon,
  additionalSessionPrice,
  customerInfo,
  isEligibleForContinuityPlan,
  hideMuseHeadset,
  shouldRenderNewPlans,
  postMembershipPlan,
  extraCredit,
  discountCouponText,
  funcURL,
  // ABToggle,
  ABPricingToggle,
  // beforeDiscountPlan,
  reviews,
  excludedCountries,
  deepLinkCouponCode,
  customCouponText,
  // remoteClinicsInfo,
}) => {
  const {
    userId,
    optionalUserId,
    email: emailFromUrl,
    couponCode: couponCodeURL,
    // eslint-disable-next-line camelcase
    utm_source,
    // eslint-disable-next-line camelcase
    utm_campaign,
    adminUserId,
    mtrCode,
    fbtoken,
    upgradeFromApp,
    quiz,
  } = getCurrentURLParams();

  // eslint-disable-next-line no-undef
  const firebaseAnalytics = firebase.analytics();

  const [isMatchingMDScreen] = useMediaMatch({
    mediaMatchString: `(max-width: ${mdMediaMaxWidth}px)`,
  });
  const [isMatchingXSScreen] = useMediaMatch({
    mediaMatchString: `(max-width: ${xsMediaMaxWidth}px)`,
  });
  const [isMatchingXXSScreen] = useMediaMatch({
    mediaMatchString: `(max-width: ${xsExtraMediaMaxWidth}px)`,
  });

  const [videoTestimonials] = useFirebaseDB({
    path: '/remoteClinics/videoTestimonials',
  });

  const eligibilityQuestions =
    sessionStorage.prettyQuestions &&
    JSON.parse(sessionStorage.prettyQuestions);

  const shouldIncludeMuseByQuestion =
    !eligibilityQuestions || eligibilityQuestions.doYouOwnMuse === 'No';

  const isImproveFocus =
    eligibilityQuestions &&
    eligibilityQuestions.reasonsForDoingNeuroFeedback &&
    eligibilityQuestions.reasonsForDoingNeuroFeedback.find(
      el => el === 'Improve focus'
    );
  const isPeakPerformance =
    eligibilityQuestions &&
    eligibilityQuestions.reasonsForDoingNeuroFeedback &&
    eligibilityQuestions.reasonsForDoingNeuroFeedback.find(
      el => el === 'Peak performance'
    );
  const isReduceAnxiety =
    eligibilityQuestions &&
    eligibilityQuestions.reasonsForDoingNeuroFeedback &&
    eligibilityQuestions.reasonsForDoingNeuroFeedback.find(
      el => el === 'Reduce anxiety'
    );

  const isReactivationFlow = !!adminUserId;
  const isFamilyReactivationFlow =
    isReactivationFlow && customerInfo && customerInfo.isFamilyMember;
  const shouldShowFamilyCorePackages = userId || isFamilyReactivationFlow;

  const shouldShowNewFlow = !userId && !isReactivationFlow;

  const filteredCountries = countriesDataList.filter(
    countryObject =>
      !(excludedCountries || []).find(
        excludedCountryIso2 => countryObject.iso2 === excludedCountryIso2
      )
  );

  // const neuroCoachesSectionData = Object.keys(remoteClinicsInfo).filter(
  //   clinicId => remoteClinicsInfo[clinicId].subscriptionPageCard
  // );
  // const filteredNeuroCoachesSectionData = neuroCoachesSectionData.filter(
  //   clinicId => {
  //     if (sessionStorage.countryNamesEn === 'US') {
  //       return remoteClinicsInfo[clinicId].subscriptionPageCard.country.US.show;
  //     }
  //     return remoteClinicsInfo[clinicId].subscriptionPageCard.country.other
  //       .show;
  //   }
  // );
  // const sortedFilteredNeuroCoachesSectionData = filteredNeuroCoachesSectionData.sort(
  //   (a, b) => {
  //     if (sessionStorage.countryNamesEn === 'US') {
  //       return (
  //         remoteClinicsInfo[a].subscriptionPageCard.country.US.index -
  //         remoteClinicsInfo[b].subscriptionPageCard.country.US.index
  //       );
  //     }
  //     return (
  //       remoteClinicsInfo[a].subscriptionPageCard.country.other.index -
  //       remoteClinicsInfo[b].subscriptionPageCard.country.other.index
  //     );
  //   }
  // );

  // const [imageLoaded, setImageLoaded] = useState(false);

  // Makes images load instantly
  // useEffect(() => {
  //   const preloadImages = () => {
  //     sortedFilteredNeuroCoachesSectionData.forEach(clinicId => {
  //       // eslint-disable-next-line no-undef
  //       const img = new Image();
  //       img.src = isMatchingXSScreen
  //         ? remoteClinicsInfo[clinicId].subscriptionPageCard.info.mobileImage
  //         : remoteClinicsInfo[clinicId].subscriptionPageCard.info.image;
  //     });
  //   };

  //   preloadImages();
  // }, [remoteClinicsInfo]);

  // const handleImageLoad = () => {
  //   setImageLoaded(true);
  // };

  const prvUserId = usePreviousValue(userId);
  const sendEvents = useCallback(
    async (eventName, params = {}) => {
      // eslint-disable-next-line no-undef
      amplitude.track(eventName, params);
      firebaseAnalytics.logEvent(eventName);
      return window.fetch(`${funcURL}mtr-leadsFlowHelper`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          eventName,
          eventDetails: {
            // eslint-disable-next-line camelcase
            utm_source: utm_source || '',
            // eslint-disable-next-line camelcase
            utm_campaign: utm_campaign || '',
            questions:
              (sessionStorage.prettyQuestions &&
                JSON.stringify(sessionStorage.prettyQuestions)) ||
              {},
            ...params,
            ...(sessionStorage.eligibilityId
              ? { eligibilityId: sessionStorage.eligibilityId }
              : {}),
          },
        }),
      });
    },
    // eslint-disable-next-line camelcase
    [nodeURL, utm_campaign, utm_source]
  );

  const corePlanOptions = plans.corePlans && plans.corePlans.planOptions;
  const continuityPlanOptions =
    plans.continuityPlans && plans.continuityPlans.planOptions;
  const familyPlanOptions =
    plans.familyMemberPlans && plans.familyMemberPlans.planOptions;
  const lowestFamilyPricePerWeek = (() => {
    const { price, billingPeriodInMonths } = nth(familyPlanOptions, -1);
    return getPlanPriceByWeek({ price, billingPeriodInMonths });
  })();

  let initialPlanType = shouldShowFamilyCorePackages
    ? planTypes.familyMemberPlans
    : planTypes.corePlans;

  if (shouldRenderNewPlans && isReactivationFlow) {
    initialPlanType = planTypes.continuityPlans;
  }

  const [currentStep, setCurrentStep] = useState(stepperSteps.choosePlan);
  const prvStep = usePreviousValue(currentStep);
  const [currentStepForChoosePlan, setCurrentStepForChoosePlan] = useState(
    choosePlanSteps.paymentOptions
  );
  const prvCurrentStepForChoosePlan = usePreviousValue(
    currentStepForChoosePlan
  );
  const [selectedPlanId, setSelectedPlanId] = useState(
    // eslint-disable-next-line no-nested-ternary
    shouldRenderNewPlans
      ? plans[initialPlanType].planOptions[0].stripeSubId
      : plans[initialPlanType].planOptions.find(
          plan => plan.billingPeriodInMonths === 3
        ).stripeSubId
  );
  const [selectedPlanType, setSelectedPlanType] = useState(initialPlanType);
  const [selectedMuseOption, setSelectedMuseOption] = useState(
    isReactivationFlow ||
      shouldShowFamilyCorePackages ||
      !shouldIncludeMuseByQuestion
      ? undefined
      : museOptions.includeAMuseDevice
  );
  const [compatibleMuseText, setCompatibleMuseText] = useState(null);

  const [shippingFormValues, setShippingFormValues] = useState({
    country: 'us',
    email: emailFromUrl || '',
    state: '',
  });
  const [shippingFormErrors, setShippingFormErrors] = useState({});
  const [shippingFormState, setShippingFormState] = useState({
    isDirty: false,
  });

  const [paymentFormValues, setPaymentFormValues] = useState({
    email: emailFromUrl || '',
  });
  const [paymentFormErrors, setPaymentFormErrors] = useState({});
  const [paymentFormState, setPaymentFormState] = useState({
    isDirty: false,
  });

  const [faqEventTimeout, setFaqEventTimeout] = useState(false);

  const museOptionsLabelNodes = {
    iWillBuyMuseMyself: 'I will buy a compatible Muse separately',
    iAlreadyOwnAMuseDevice: 'I already own a Muse device',
    includeAMuseDevice: `I would like to purchase a Muse 2 headband for $${museDevicePrice}`,
  };

  const [shouldShowLoader, setShouldShowLoader] = useState(false);

  const [isPaymentFailedDialogOpen, setIsPaymentFailedDialogOpen] = useState(
    false
  );
  const [
    isAccountReactivatedSuccessfullyDialogOpen,
    setIsAccountReactivatedSuccessfullyDialogOpen,
  ] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const [creditCardError, setCreditCardError] = useState(null);

  const cardElementRef = useRef();

  const [filteredFaqs, setFilteredFaqs] = useState(null);
  const [expandedFaqs, setExpandedFaqs] = useState([]);

  const [couponCode, setCouponCode] = useState(
    deepLinkCouponCode ||
      couponCodeURL ||
      (!userId && discountCouponAmount > 0 && discountCoupon) ||
      ''
  );
  const [couponCodeError, setCouponCodeError] = useState(null);
  const [wasInitialCouponHandled, setWasInitialCouponHandled] = useState(false);
  const [lastValidCouponCode, setLastValidCouponCode] = useState('');
  const [couponDiscount, setCouponDiscount] = useState(initialCouponDiscount);
  const [isCouponCodeBtnLoading, setIsCouponCodeBtnLoading] = useState(false);

  const [togglePayUpfront, setTogglePayUpfront] = useState(true);
  const [displayMuseModal, setDisplayMuseModal] = useState(false);
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(false);

  const [shouldDisplayCoupon, setShouldDisplayCoupon] = useState(false);

  const [displayVideoGuide, setDisplayVideoGuide] = useState(false);

  // const [shouldLoadBanner, setShouldLoadBanner] = useState(false);

  // const [isReviewAtStart, setIsReviewAtStart] = useState(true);
  // const [isReviewAtEnd, setIsReviewAtEnd] = useState(false);

  // const [neuroCoachSelectedIndex, setNeuroCoachSelectedIndex] = useState(0);
  // const [toggleNeurocoachAnimation, setToggleNeurocoachAnimation] = useState(
  //   false
  // );

  const [isYoutubeDialogOpen, setIsYoutubeDialogOpen] = useState(false);
  const [currentVideoDialogInfo, setCurrentVideoDialogInfo] = useState({
    videoId: null,
    videoTitle: null,
    // width: 0,
    // height: 0,
    className: '',
  });

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShouldLoadBanner(true);
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  useEffect(() => {
    if (isYoutubeDialogOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isYoutubeDialogOpen]);
  // useEffect(() => {
  //   const myInterval = setInterval(() => {
  //     setToggleNeurocoachAnimation(false);
  //     if (ABPricingToggle) {
  //       setTimeout(() => {
  //         setNeuroCoachSelectedIndex(
  //           prevNeuroCoachSelectedIndex =>
  //             (prevNeuroCoachSelectedIndex + 1) %
  //             sortedFilteredNeuroCoachesSectionData.length
  //         );
  //         setImageLoaded(false);
  //         setToggleNeurocoachAnimation(true);
  //       }, 100);
  //     }
  //   }, 10000);
  //   return () => clearInterval(myInterval);
  // }, []);

  const onCouponCodeChange = useCallback(event => {
    setCouponCode(event.target.value);
  }, []);

  const history = useHistory();

  // const [touchStartX, setTouchStartX] = useState(0);
  // const [touchEndX, setTouchEndX] = useState(0);

  const orderedStepperSteps =
    // eslint-disable-next-line no-nested-ternary
    userId || isReactivationFlow
      ? [stepperSteps.choosePlan, stepperSteps.cart, stepperSteps.payment]
      : shouldShowNewFlow
      ? [stepperSteps.choosePlan, stepperSteps.shippingB, stepperSteps.paymentB]
      : [
          stepperSteps.choosePlan,
          stepperSteps.shippingInfo,
          stepperSteps.cart,
          stepperSteps.payment,
        ];

  const sendAmplitudeEvent = (name, params) => {
    // eslint-disable-next-line no-undef
    amplitude.track(name, params);
  };

  const applyCoupon = useCallback(async () => {
    const response = await window.fetch(
      `${funcURL}shop-myndliftCouponDiscount`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          couponCode,
          // eslint-disable-next-line no-nested-ternary
          ...(userId ? { userId } : adminUserId ? { userId: adminUserId } : {}),
          ...(mtrCode ? { activationCode: mtrCode } : {}),
        }),
      }
    );

    const responseData = await response.json();

    if (responseData && responseData.result === true) {
      setCouponDiscount({
        type: responseData.discountType,
        amount: responseData.discountAmount,
      });
      setLastValidCouponCode(couponCode);

      if (responseData.discountAmount === 0) {
        setCouponCodeError('Invalid Coupon');
      } else {
        setCouponCodeError(null);
      }
    } else {
      setCouponCodeError(responseData.message || 'Invalid Coupon');
    }
  }, [funcURL, couponCode, userId]);

  const onApplyCoupon = useCallback(async () => {
    setIsCouponCodeBtnLoading(true);
    await applyCoupon();
    setIsCouponCodeBtnLoading(false);
    logMTRSubscriptionAppEvent({
      name: 'on_apply_coupon',
      data: {
        couponCode,
      },
    });
    sendAmplitudeEvent('on_apply_coupon', { couponCode });
  }, [applyCoupon, couponCode]);

  const expandContinuityQuestionsFaqs = () =>
    setExpandedFaqs(oldExpandedFaqs => [
      ...new Set(
        oldExpandedFaqs.concat(
          faqs.filter(faq => faq.isContinuityQuestion).map(faq => faq.question)
        )
      ),
    ]);

  const expandNCSessionQuestionsFaqs = () =>
    setExpandedFaqs(oldExpandedFaqs => [
      ...new Set(
        oldExpandedFaqs.concat(
          faqs.filter(faq => faq.isNCQuestion).map(faq => faq.question)
        )
      ),
    ]);

  useEffect(() => {
    if (quiz) {
      // eslint-disable-next-line no-undef
      localStorage.setItem('isUserDidEligibilty', true);
    }
  }, []);

  useEffect(
    () => {
      // eslint-disable-next-line no-undef
      const identifyEvent2 = new amplitude.Identify();
      identifyEvent2.set(
        'mtr_subscription_page_ab_test_one_price',
        ABPricingToggle ? 'B' : 'A'
      );

      // eslint-disable-next-line no-undef
      amplitude.identify(identifyEvent2);

      sendEvents('subscription_page_view', {
        isReactivationFlow: !!adminUserId && !isFamilyReactivationFlow,
        isFamilyReactivationFlow: !!isFamilyReactivationFlow,
        isAddFamillyMemberflow: !!userId,
        variant_pricing_dark: ABPricingToggle ? 'B' : 'A',
      });
    },
    [
      // ABPricingToggle
    ]
  );

  useEffect(() => {
    (async () => {
      if (!wasInitialCouponHandled) {
        if (deepLinkCouponCode || couponCodeURL) {
          await applyCoupon();
        }
        if (
          !deepLinkCouponCode &&
          !couponCodeURL &&
          discountCouponAmount > 0 &&
          !userId
        ) {
          await applyCoupon();
        }
        setWasInitialCouponHandled(true);
      }
    })();
  }, [
    applyCoupon,
    couponCodeURL,
    discountCouponAmount,
    userId,
    wasInitialCouponHandled,
  ]);

  useEffect(() => {
    if (userId !== prvUserId) {
      const selectedPlanIndex = plans[selectedPlanType].planOptions.findIndex(
        plan => plan.stripeSubId === selectedPlanId
      );

      setSelectedPlanType(initialPlanType);
      setSelectedPlanId(
        plans[initialPlanType].planOptions[selectedPlanIndex].stripeSubId
      );
    }
  }, [
    initialPlanType,
    plans,
    selectedPlanId,
    selectedPlanType,
    userId,
    prvUserId,
  ]);

  useEffect(() => {
    if (shouldRenderNewPlans) {
      const hasMonthlyPlans = plans[initialPlanType].planOptions.find(
        option => option.billingPeriodInMonths === 1
      );
      const hasUpfrontPlans = plans[initialPlanType].planOptions.find(
        option => option.billingPeriodInMonths !== 1
      );
      if (!hasMonthlyPlans) {
        setTogglePayUpfront(true);
        setIsSwitchDisabled(true);
      }
      if (!hasUpfrontPlans) {
        setTogglePayUpfront(false);
        setIsSwitchDisabled(true);
      }
    }
  }, []);

  const backToEligibilty = () => {
    // eslint-disable-next-line no-undef
    localStorage.setItem('isUserDidEligibilty', false);
    window.open(userId ? `./?userId=${userId}` : './', '_self');
  };

  const getShippingFormErrors = useCallback(() => {
    return Object.keys(shippingFormRequiredFormattedFieldNames).reduce(
      (acc, fieldName) => {
        if (get(shippingFormValues, fieldName, '').trim() === '') {
          if (shippingFormValues.country === 'us' || fieldName !== 'state') {
            const formattedFieldName =
              shippingFormRequiredFormattedFieldNames[fieldName];
            acc[fieldName] = `${formattedFieldName} is required!`;
          }
        } else {
          delete acc[fieldName];

          if (fieldName === 'phone') {
            if (!isValidPhone(shippingFormValues.phone)) {
              acc.phone = 'Invalid phone!';
            } else {
              delete acc.phone;
            }
          }

          if (fieldName === 'email') {
            if (!isValidEmail(shippingFormValues.email)) {
              acc.email = 'Invalid email!';
            } else {
              delete acc.email;
            }
          }

          if (fieldName === 'state') {
            if (
              !isValidZipCode(
                shippingFormValues.zipCode,
                shippingFormValues.country
              )
            ) {
              acc.zipCode = 'Invalid zip code!';
            } else {
              delete acc.zipCode;
            }
          }
        }
        return acc;
      },
      {}
    );
  }, [shippingFormValues]);

  const getPaymentFormErrors = useCallback(() => {
    return Object.keys(
      shouldShowNewFlow
        ? newPaymentFormRequiredFormattedFieldNames
        : paymentFormRequiredFormattedFieldNames
    ).reduce((acc, fieldName) => {
      if (get(paymentFormValues, fieldName, '').trim() === '') {
        const formattedFieldName = shouldShowNewFlow
          ? newPaymentFormRequiredFormattedFieldNames[fieldName]
          : paymentFormRequiredFormattedFieldNames[fieldName];
        acc[fieldName] = `${formattedFieldName} is required!`;
      } else {
        delete acc[fieldName];

        if (fieldName === 'email') {
          if (!isValidEmail(paymentFormValues.email)) {
            acc.email = 'Invalid email!';
          } else {
            delete acc.email;
          }
        }
      }
      return acc;
    }, {});
  }, [paymentFormValues]);

  const handleFailedPayment = useCallback(
    message => setIsPaymentFailedDialogOpen(message || true),
    []
  );

  const getCurrentPlanDetails = useCallback(() => {
    const { planOptions } = plans[selectedPlanType];
    const currentPlan = planOptions.find(
      plan => plan.stripeSubId === selectedPlanId
    );
    const currentPlanIndex = planOptions.findIndex(
      plan => plan.stripeSubId === selectedPlanId
    );
    const { billingPeriodInMonths, price } = currentPlan;
    const pricePerWeek = getPlanPriceByWeek({
      price,
      billingPeriodInMonths,
    });
    const savings = getPlanSaving({
      price,
      billingPeriodInMonths,
      monthlyPlanPrice: planOptions[0].price,
    });
    const nameBasedOnBillingPeriod = getPlanTypeByBillingPeriodInMonths({
      billingPeriodInMonths,
    });

    const dateFormat = 'MMMM DD, YYYY';
    return {
      price,
      pricePerWeek,
      weeksToBePaid: billingPeriodInMonths * 4,
      nextBillingDate: dayJS()
        .add(billingPeriodInMonths, 'month')
        .format(dateFormat),
      savings: shouldRenderNewPlans ? 0 : savings,
      packageIndex: currentPlanIndex,
      nameBasedOnBillingPeriod,
      nameBasedOnNewPricing:
        currentPlan.billingPeriodInMonths === 1 ? 'Monthly' : 'Upfront',
      commitmentInMonths: currentPlan.commitmentInMonths,
      membershipNumCoachCalls: currentPlan.membershipNumCoachCalls,
      billingPeriodInMonths,
    };
  }, [plans, selectedPlanId, selectedPlanType]);

  const getDiscountedAmountBasedOnCoupon = useCallback(() => {
    const currentPlanPrice = getCurrentPlanDetails().price;
    let discount = 0;

    if (couponDiscount.type === 'Const') {
      discount = couponDiscount.amount;
    }
    if (couponDiscount.type === 'Percent') {
      const totalDue =
        selectedMuseOption === museOptions.includeAMuseDevice
          ? museDevicePrice + currentPlanPrice
          : currentPlanPrice;
      discount = (couponDiscount.amount / 100) * totalDue;
    }

    return parseFloat(discount.toFixed(2), 10);
  }, [couponDiscount, getCurrentPlanDetails, selectedMuseOption]);

  // const calcDiscountedAmountBasedOnCoupon = useCallback(
  //   price => {
  //     let discount = 0;

  //     if (couponDiscount.type === 'Const') {
  //       discount = couponDiscount.amount;
  //     }
  //     if (couponDiscount.type === 'Percent') {
  //       const totalDue =
  //         selectedMuseOption === museOptions.includeAMuseDevice
  //           ? museDevicePrice + price
  //           : price;
  //       discount = (couponDiscount.amount / 100) * totalDue;
  //     }

  //     return parseFloat(discount.toFixed(2), 10);
  //   },
  //   [couponDiscount, getCurrentPlanDetails, selectedMuseOption]
  // );

  const onChangeFamilyFlowHardwareSharingStatus = useCallback(() => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    if (urlSearchParams.has('userId')) {
      urlSearchParams.delete('userId');
    } else {
      urlSearchParams.append('userId', optionalUserId);
    }
    history.replace(
      `${history.location.pathname}?${urlSearchParams.toString()}`
    );
  }, [history, optionalUserId]);

  const tryUserSignUp = useCallback(
    async ({ paymentForm, shippingForm, stripeToken }) => {
      const payload = {
        paymentToken: stripeToken,
        firstName: shouldShowNewFlow
          ? shippingForm.firstName
          : paymentForm.firstName,
        lastName: shouldShowNewFlow
          ? shippingForm.lastName
          : paymentForm.lastName,
        groupId,
        packageIndex: getCurrentPlanDetails().packageIndex,
        utm_source,
        utm_campaign,
        questions:
          (sessionStorage.prettyQuestions &&
            JSON.parse(sessionStorage.prettyQuestions)) ||
          {},
        ...(userId
          ? {
              userId,
              couponCode,
            }
          : {
              hasCompatibleHeadset:
                selectedMuseOption !== museOptions.includeAMuseDevice,

              couponCode,
              email: shouldShowNewFlow
                ? shippingForm.email
                : paymentForm.email.trim(),
              shippingDetails: {
                email: shippingForm.email,
                firstName: shippingForm.firstName,
                lastName: shippingForm.lastName,
                address: shippingForm.street,
                apt: shippingForm.apt,
                city: shippingForm.city,
                state: shippingForm.state,
                country: shippingForm.country,
                zip: shippingForm.zipCode,
                phone: shippingForm.phone,
              },
            }),
        isWebView: !!(window.Android || window.webkit),
        upgradeFromApp,
      };

      try {
        const response = await window.fetch(`${funcURL}mtr-remoteUserSignup`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify(payload),
        });

        const responseData = await response.json();

        if (responseData.result === true) {
          sessionStorage.newUserName =
            shippingForm.firstName || paymentForm.firstName;
          history.push(
            `/successful-payment?userId=${responseData.userId}&activationCode=${responseData.activationCode}&groupId=${groupId}`
          );
          // eslint-disable-next-line no-undef
          const currentPlanDetails = getCurrentPlanDetails();
          const totalDue =
            selectedMuseOption === museOptions.includeAMuseDevice
              ? museDevicePrice + currentPlanDetails.price
              : currentPlanDetails.price;
          // eslint-disable-next-line no-undef
          ga('send', {
            hitType: 'event',
            eventCategory: 'MTR',
            eventAction: 'Purchased',
            eventLabel: getCurrentPlanDetails().nameBasedOnBillingPeriod,
            eventValue: totalDue,
          });
          // eslint-disable-next-line no-undef
          fbq('track', 'Purchased', { currency: 'USD', value: totalDue });
          const calcScoreForPurchase = () => {
            switch (currentPlanDetails.billingPeriodInMonths) {
              case 1:
                return 100;
              case 3:
                return 200;
              case 6:
                return 300;
              case 12:
                return 400;
              default:
                return 100;
            }
          };
          sendAmplitudeEvent('Purchased', {
            label: getCurrentPlanDetails().nameBasedOnBillingPeriod,
            currency: 'USD',
            value: getCurrentPlanDetails().price,
            score: calcScoreForPurchase(),
            isAddFamillyMemberflow: !!userId,
            isFamilyReactivationFlow: false,
            isReactivationFlow: false,
            includesHeadset:
              selectedMuseOption === museOptions.includeAMuseDevice,
            couponUsed: couponCode,
          });
          if (isProd) {
            triggerShareASaleService(totalDue, responseData.userId);
          }
        }
        if (responseData.result === false) {
          // eslint-disable-next-line no-undef
          ga('send', {
            hitType: 'event',
            eventCategory: 'MTR',
            eventAction: 'PaymentFailed',
            eventLabel: getCurrentPlanDetails().nameBasedOnBillingPeriod,
          });
          sendAmplitudeEvent('PaymentFailed', {
            label: getCurrentPlanDetails().nameBasedOnBillingPeriod,
            message: responseData.message,
          });
          handleFailedPayment(responseData.message);
          setShouldShowLoader(false);
        }
      } catch (err) {
        handleFailedPayment(err);
        setShouldShowLoader(false);
      }
    },
    [
      groupId,
      getCurrentPlanDetails,
      userId,
      couponCode,
      selectedMuseOption,
      funcURL,
      history,
      handleFailedPayment,
      upgradeFromApp,
    ]
  );

  const tryUserReactivation = useCallback(
    async ({ paymentForm, stripeToken }) => {
      const payload = {
        paymentToken: stripeToken,
        firstName: paymentForm.firstName,
        lastName: paymentForm.lastName,
        groupId,
        packageIndex: getCurrentPlanDetails().packageIndex,
        hasCompatibleHeadset:
          selectedMuseOption !== museOptions.includeAMuseDevice,
        couponCode,
        email: paymentForm.email.trim(),
        planType: selectedPlanType,
        activationCode: mtrCode,
        userId: adminUserId,
        remoteAdminId: adminUserId,
        fbtoken,
      };

      try {
        const responseData = await postToFunctions({
          path: 'mtr-reactivateSubscription',
          data: payload,
        });

        if (responseData.result === true) {
          setIsAccountReactivatedSuccessfullyDialogOpen(true);
          // eslint-disable-next-line no-undef
          ga('send', {
            hitType: 'event',
            eventCategory: 'MTR',
            eventAction: 'Purchased',
            eventLabel: getCurrentPlanDetails().nameBasedOnBillingPeriod,
          });
          sendAmplitudeEvent('Purchased', {
            label: getCurrentPlanDetails().nameBasedOnBillingPeriod,
            currency: 'USD',
            value: getCurrentPlanDetails().price,
            isAddFamillyMemberflow: false,
            isFamilyReactivationFlow: !!isFamilyReactivationFlow,
            isReactivationFlow: !!adminUserId && !isFamilyReactivationFlow,
            includesHeadset:
              selectedMuseOption === museOptions.includeAMuseDevice,
            couponUsed: !!couponCode,
          });
        }
        if (responseData.result === false) {
          handleFailedPayment(responseData.message);
          setShouldShowLoader(false);
        }
      } catch (err) {
        handleFailedPayment(err);
        setShouldShowLoader(false);
      }
    },
    [
      adminUserId,
      couponCode,
      fbtoken,
      getCurrentPlanDetails,
      groupId,
      handleFailedPayment,
      mtrCode,
      nodeURL,
      selectedMuseOption,
      selectedPlanType,
      isFamilyReactivationFlow,
    ]
  );

  const handlePayment = useCallback(() => {
    setShouldShowLoader(true);
    stripe
      .createToken()
      .then(async result => {
        if (result.error) {
          if (result.error.type !== 'validation_error') {
            // eslint-disable-next-line no-undef
            ga('send', {
              hitType: 'event',
              eventCategory: 'MTR',
              eventAction: 'PaymentFailed',
              eventLabel: getCurrentPlanDetails().nameBasedOnBillingPeriod,
            });
            setStripeError(result.error.message);
            sendAmplitudeEvent('PaymentFailed', {
              label: getCurrentPlanDetails().nameBasedOnBillingPeriod,
            });
          }
          setShouldShowLoader(false);
        } else {
          if (isReactivationFlow) {
            await tryUserReactivation({
              paymentForm: paymentFormValues,
              stripeToken: result.token.id,
            });
          } else {
            await tryUserSignUp({
              paymentForm: paymentFormValues,
              shippingForm: shippingFormValues,
              stripeToken: result.token.id,
            });
          }
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-undef
        ga('send', {
          hitType: 'event',
          eventCategory: 'MTR',
          eventAction: 'PaymentFailed',
          eventLabel: getCurrentPlanDetails().nameBasedOnBillingPeriod,
        });
        sendAmplitudeEvent('PaymentFailed', {
          label: getCurrentPlanDetails().nameBasedOnBillingPeriod,
        });
      });

    sendEvents('remote_payment_page_on_pay', {});
  }, [
    stripe,
    sendEvents,
    getCurrentPlanDetails,
    isReactivationFlow,
    tryUserReactivation,
    paymentFormValues,
    tryUserSignUp,
    shippingFormValues,
  ]);

  const onBackBtnClick = useCallback(() => {
    logMTRSubscriptionAppEvent({
      name: 'on_back_btn_click',
      data: {
        currentStep,
        currentStepForChoosePlan,
      },
    });
    sendAmplitudeEvent('on_back_btn_click', {
      currentStep,
      currentStepForChoosePlan,
    });
    setExpandedFaqs([]);
    // eslint-disable-next-line no-use-before-define
    onClearSearch();
    switch (currentStep) {
      case stepperSteps.choosePlan:
        if (currentStepForChoosePlan === choosePlanSteps.museOptions) {
          setCurrentStepForChoosePlan(choosePlanSteps.paymentOptions);
        }
        break;
      case stepperSteps.shippingInfo:
        setCurrentStep(stepperSteps.choosePlan);
        break;
      case stepperSteps.cart:
        if (userId || isReactivationFlow) {
          setCurrentStep(stepperSteps.choosePlan);
        } else {
          setCurrentStep(stepperSteps.shippingInfo);
        }

        break;
      case stepperSteps.payment:
        setCurrentStep(stepperSteps.cart);
        break;
      case stepperSteps.shippingB:
        setCurrentStep(stepperSteps.choosePlan);
        break;
      case stepperSteps.paymentB:
        setCurrentStep(stepperSteps.shippingB);
        break;
      default:
        setCurrentStepForChoosePlan(null);
    }
  }, [currentStep, currentStepForChoosePlan, isReactivationFlow, userId]);

  const fireClientFinishFillDetails = () => {
    sendEvents('client_finished_filling_details', shippingFormValues);
    const questions =
      sessionStorage.prettyQuestions &&
      JSON.parse(sessionStorage.prettyQuestions);

    sendEvents('remote_payment_page_on_checkout', {
      questions: {
        ...(questions || {}),
        email:
          (questions && questions.email) ||
          shippingFormValues.email ||
          'unknown',
      },
      score: 100,
    });
  };

  const onContinueBtnClick = useCallback(() => {
    const latestShippingFormErrors = getShippingFormErrors();
    const latestPaymentFormErrors = getPaymentFormErrors();
    logMTRSubscriptionAppEvent({
      name: 'on_continue_btn_click',
      data: {
        currentStep,
        currentStepForChoosePlan,
      },
      score: 100,
    });
    sendAmplitudeEvent('on_continue_btn_click', {
      currentStep,
      currentStepForChoosePlan,
    });
    setExpandedFaqs([]);
    window.scrollTo(0, 0);

    const questions =
      sessionStorage.prettyQuestions &&
      JSON.parse(sessionStorage.prettyQuestions);
    // eslint-disable-next-line no-use-before-define
    onClearSearch();
    switch (currentStep) {
      case stepperSteps.choosePlan:
        if (currentStepForChoosePlan === choosePlanSteps.paymentOptions) {
          const planInd = plans[selectedPlanType].planOptions.findIndex(
            plan => plan.stripeSubId === selectedPlanId
          );
          const planPrice = plans[selectedPlanType].planOptions[planInd].price;
          // eslint-disable-next-line no-undef
          ga('send', {
            hitType: 'event',
            eventCategory: 'MTR',
            eventAction: 'CHOSE-PLAN',
            eventValue: 100,
            userDefinedValue: 100,
          });
          sendAmplitudeEvent('CHOSE-PLAN', { label: planPrice, score: 100 });
          // eslint-disable-next-line no-undef
          fbq('track', 'AddToCart', { score: 100, value: 100 });
          // eslint-disable-next-line no-undef
          fbq('trackCustom', 'CHOSE-PLAN', { score: 100, value: 100 });

          if (userId || isReactivationFlow) {
            setCurrentStep(stepperSteps.cart);
          } else {
            setCurrentStep(stepperSteps.shippingInfo);
          }
        }
        if (currentStepForChoosePlan === choosePlanSteps.museOptions) {
          setCurrentStep(stepperSteps.shippingInfo);
          // eslint-disable-next-line no-undef
          ga('send', {
            hitType: 'event',
            eventCategory: 'MTR',
            eventAction: 'CHOSE-HW-OPTION',
            eventValue: 100,
            userDefinedValue: 100,
          });
          // eslint-disable-next-line no-undef
          fbq('track', 'InitiateCheckout');
          // eslint-disable-next-line no-undef
          fbq('trackCustom', 'CHOSE-HW-OPTION', { score: 100, value: 100 });
          sendAmplitudeEvent('CHOSE-HW-OPTION', {
            label: selectedMuseOption,
            score: 100,
          });
        }
        if (shouldShowNewFlow) {
          setCurrentStep(stepperSteps.shippingB);
        }
        break;
      case stepperSteps.shippingInfo:
        setShippingFormState(prvState => ({
          ...prvState,
          isDirty: true,
        }));
        if (Object.values(latestShippingFormErrors).length === 0) {
          setPaymentFormValues(prvState => ({
            ...prvState,
            firstName: shippingFormValues.firstName,
            lastName: shippingFormValues.lastName,
            email: shippingFormValues.email,
          }));
          setCurrentStep(stepperSteps.cart);
          // eslint-disable-next-line no-undef
          ga('send', {
            hitType: 'event',
            eventCategory: 'MTR',
            eventAction: 'FILLED-SHIPPING',
            eventLabel: 100,
            userDefinedValue: 100,
          });
          // eslint-disable-next-line no-undef
          fbq('trackCustom', 'CHOSE-HW-OPTION', { score: 100, value: 100 });
          sendEvents('client_finished_filling_details', shippingFormValues);
        }

        sendEvents('remote_payment_page_on_checkout', {
          questions: {
            ...(questions || {}),
            email:
              (questions && questions.email) ||
              shippingFormValues.email ||
              'unknown',
          },
          score: 100,
        });
        break;
      case stepperSteps.cart:
        setCurrentStep(stepperSteps.payment);
        // eslint-disable-next-line no-undef
        ga('send', {
          hitType: 'event',
          eventCategory: 'MTR',
          eventAction: 'WENT-TO-PAYMENT',
          eventLabel: 100,
          userDefinedValue: 100,
        });
        sendAmplitudeEvent('WENT-TO-PAYMENT', { score: 100 });
        // eslint-disable-next-line no-undef
        fbq('trackCustom', 'CHOSE-HW-OPTION', { score: 100, value: 100 });
        break;
      case stepperSteps.payment:
        setPaymentFormState(prvState => ({
          ...prvState,
          isDirty: true,
        }));
        if (
          Object.values(latestPaymentFormErrors).length === 0 &&
          !creditCardError
        ) {
          const planInd = plans[selectedPlanType].planOptions.findIndex(
            plan => plan.stripeSubId === selectedPlanId
          );
          const planPrice = plans[selectedPlanType].planOptions[planInd].price;
          // eslint-disable-next-line no-undef
          ga('send', {
            hitType: 'event',
            eventCategory: 'MTR',
            eventAction: 'ATTEMPTED-PAYMENT',
            eventLabel: planPrice,
          });
          sendAmplitudeEvent('ATTEMPTED-PAYMENT');
          handlePayment();
        }
        break;
      case stepperSteps.shippingB:
        setShippingFormState(prvState => ({
          ...prvState,
          isDirty: true,
        }));

        if (Object.values(latestShippingFormErrors).length === 0) {
          fireClientFinishFillDetails();
          setCurrentStep(stepperSteps.paymentB);
        } else {
          scrollTo('shipping_information');
        }
        break;
      case stepperSteps.paymentB:
        setPaymentFormState(prvState => ({
          ...prvState,
          isDirty: true,
        }));

        if (
          Object.values(latestPaymentFormErrors).length === 0 &&
          !creditCardError
        ) {
          const planInd = plans[selectedPlanType].planOptions.findIndex(
            plan => plan.stripeSubId === selectedPlanId
          );
          const planPrice = plans[selectedPlanType].planOptions[planInd].price;
          // eslint-disable-next-line no-undef
          ga('send', {
            hitType: 'event',
            eventCategory: 'MTR',
            eventAction: 'ATTEMPTED-PAYMENT',
            eventLabel: planPrice,
          });
          sendAmplitudeEvent('ATTEMPTED-PAYMENT');
          handlePayment();
        } else {
          scrollTo('payment_information');
        }
        break;
      default:
        setCurrentStepForChoosePlan(null);
    }
  }, [
    getShippingFormErrors,
    getPaymentFormErrors,
    currentStep,
    currentStepForChoosePlan,
    sendEvents,
    creditCardError,
    userId,
    isReactivationFlow,
    paymentFormValues.firstName,
    paymentFormValues.lastName,
    paymentFormValues.email,
    shippingFormValues.firstName,
    shippingFormValues.lastName,
    shippingFormValues.email,
    handlePayment,
  ]);

  const createOnTextChangeCallback = useCallback(
    fieldName => event => {
      const newValue = event.target ? event.target.value : `+${event}`;
      setShippingFormValues(prvValues => ({
        ...prvValues,
        [fieldName]: newValue,
      }));
    },
    []
  );

  const createOnTextChangeCallbackForPaymentForm = useCallback(
    fieldName => event => {
      const newValue = event.target.value;
      setPaymentFormValues(prvValues => ({
        ...prvValues,
        [fieldName]: newValue,
      }));
    },
    []
  );

  const onCountryChange = event => {
    const newValue = event.target.value;
    setShippingFormValues(prvValues => ({
      ...prvValues,
      country: newValue,
      state: '',
    }));
  };

  const onStateChange = event => {
    const newValue = event.target.value;
    setShippingFormValues(prvValues => ({
      ...prvValues,
      state: newValue,
    }));
  };

  const onSelectedMuseOptionChange = useCallback((event, newValue) => {
    setSelectedMuseOption(newValue);
    logMTRSubscriptionAppEvent({
      name: 'on_muse_option_changed',
      data: {
        selected_option_id: newValue,
      },
    });
    sendAmplitudeEvent('on_muse_option_changed', {
      selected_option_id: newValue,
    });
    if (newValue === museOptions.iWillBuyMuseMyself) {
      setCompatibleMuseText(
        <span>
          Perfect. We will ship you the rest of the Myndlift kit! You can
          purchase a Muse headset from Amazon or directly from{' '}
          <a
            href="https://choosemuse.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.choosemuse.com
          </a>{' '}
          (we support all available models).
        </span>
      );
    } else if (newValue === museOptions.includeAMuseDevice) {
      setCompatibleMuseText(<span>Perfect! We’ll add this to your cart.</span>);
    } else if (newValue === museOptions.iAlreadyOwnAMuseDevice) {
      setCompatibleMuseText(
        <>
          <span>
            Please note that Myndlift is compatible with the following models:
          </span>
          <ul className={styles.list_of_supported_muses}>
            <li>Original Muse (Muse 1 with only one USB port)</li>
            <li>Muse 2</li>
            <li>Muse S</li>
          </ul>
        </>
      );
    }
  }, []);

  useEffect(() => {
    if (shippingFormState.isDirty) {
      setShippingFormErrors(getShippingFormErrors());
    }
  }, [shippingFormState, getShippingFormErrors]);

  useEffect(() => {
    if (paymentFormState.isDirty) {
      setPaymentFormErrors(getPaymentFormErrors());
    }
  }, [paymentFormState, getPaymentFormErrors]);

  useEffect(() => {
    if (filteredFaqs === null && faqs !== null) {
      let faqsToShow = JSON.parse(JSON.stringify(faqs));
      if (isReactivationFlow) {
        faqsToShow = faqs.filter(faq => faq.isReactivationQuestion);
      }
      if (!isPeakPerformance) {
        faqsToShow = faqs.filter(faq => !faq.isPeakPerformance);
      }
      setFilteredFaqs(faqsToShow);
    }
  }, [faqs, filteredFaqs]);

  const onCreditCardChange = useCallback(e => {
    if (e.error && e.error.message) {
      setCreditCardError(e.error.message);
    } else if (e.empty) {
      setCreditCardError('Credit card details are required');
    } else {
      setCreditCardError(null);
    }
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (faqEventTimeout) {
      const delayDebounceFn = setTimeout(() => {
        sendEvents('mtr_subscription_faqs', {
          action: 'search',
          search_query: faqEventTimeout,
        });
      }, 3000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [faqEventTimeout]);

  const faqsOnSearch = useCallback(
    event => {
      let faqsToShow = JSON.parse(JSON.stringify(faqs));
      if (isReactivationFlow) {
        faqsToShow = faqs.filter(faq => faq.isReactivationQuestion);
      }
      if (!isPeakPerformance) {
        faqsToShow = faqs.filter(faq => !faq.isPeakPerformance);
      }
      const searchString = event.target.value;
      setFilteredFaqs(
        faqsToShow.filter(
          faq =>
            faq.question.toLowerCase().includes(searchString.toLowerCase()) ||
            faq.answer.toLowerCase().includes(searchString.toLowerCase())
        )
      );
      setFaqEventTimeout(searchString);
    },
    [faqs]
  );
  const onClearSearch = useCallback(() => {
    setFilteredFaqs(faqs);
  }, [faqs]);

  const renderFAQS = () => (
    <div className={styles.faqs_container} id="faqs">
      {filteredFaqs && (
        <FAQS
          faqs={filteredFaqs}
          onSearch={faqsOnSearch}
          onClearSearch={onClearSearch}
          styles={styles}
          expandedFaqs={expandedFaqs}
          onFaqClick={faq => {
            setExpandedFaqs(oldExpandedFaqs =>
              oldExpandedFaqs.includes(faq.question)
                ? oldExpandedFaqs.filter(question => question !== faq.question)
                : [...oldExpandedFaqs, faq.question]
            );
            sendEvents('mtr_subscription_faqs', {
              action: expandedFaqs.includes(faq.question)
                ? 'collapse'
                : 'expand',
              faq_title: faq.question,
            });
          }}
          answersVariables={{
            additionalSessionPrice,
            familyPricePerWeek: lowestFamilyPricePerWeek,
            goldPrice: plans[planTypes.continuityPlans].planOptions[0].price,
            // eslint-disable-next-line no-nested-ternary
            trainingType: isPeakPerformance
              ? 'for peak performance'
              : // eslint-disable-next-line no-nested-ternary
              isImproveFocus
              ? 'for improved focus'
              : isReduceAnxiety
              ? 'for anxiety'
              : '',
            // eslint-disable-next-line no-nested-ternary
            trainingTypePeragraph: isPeakPerformance
              ? "This means that you're training your brain to view intense focus or increased calm as something rewarding, strengthening the synaptic connections between neurons and increasing your ability to pay attention. As a result, with practice and consistency, your brain will learn to associate the target brain activity with the reward, thereby regulating it to be in an optimal state – to improve focus, reduce stress and anxiety, and even enhance physical performance."
              : // eslint-disable-next-line no-nested-ternary
              isImproveFocus
              ? "This means that you're training your brain to view intense focus as something rewarding, strengthening the synaptic connections between neurons and increasing your ability to pay attention."
              : isReduceAnxiety
              ? "This means that you're training your brain to view the state of calm as something rewarding, strengthening the synaptic connections between neurons and increasing your ability to relax."
              : "This means that you're training your brain to view intense focus or increased calm as something rewarding, strengthening the synaptic connections between neurons and increasing your ability to pay attention. As a result, with practice and consistency, your brain will learn to associate the target brain activity with the reward, thereby regulating it to be in an optimal state – to improve focus, reduce stress and anxiety, and overall wellbeing.",
            trainActivity: isReduceAnxiety
              ? 'The calmer you are'
              : 'The better your concentration',
            // eslint-disable-next-line no-nested-ternary
            trainingTypeShort: isReduceAnxiety
              ? 'stay calm'
              : isImproveFocus
              ? 'focus'
              : 'focus or stay calm',
          }}
        />
      )}
    </div>
  );

  const renderChoosePlanStep = () => {
    let monthlyContinuityPlan;
    let monthlyContinuityPlanPricePerWeek;
    if (!shouldRenderNewPlans) {
      [monthlyContinuityPlan] = continuityPlanOptions;
      monthlyContinuityPlanPricePerWeek = getPlanPriceByWeek({
        billingPeriodInMonths: monthlyContinuityPlan.billingPeriodInMonths,
        price: monthlyContinuityPlan.price,
      });
    }
    const renderMuseOptionsStep = () => (
      <div className={styles.select_muse_options}>
        <div className={styles.muse_options}>
          <p className={styles.muse_options_intro}>
            To use Myndlift, you’ll need a Muse headband
            <div
              style={{ color: '#4be0d0', cursor: 'pointer' }}
              onClick={() => setDisplayMuseModal(true)}
            >
              What's that?
            </div>
          </p>
          <RadioGroup
            value={selectedMuseOption}
            onChange={onSelectedMuseOptionChange}
          >
            {Object.entries(
              hideMuseHeadset ? museOptionsWithoutHeadset : museOptions
            ).map(([optionKey, optionText]) => (
              <FormControlLabel
                key={optionKey}
                value={optionText}
                control={
                  <Radio
                    classes={{
                      checked: styles.radio_checked,
                      root: styles.radio_root,
                    }}
                  />
                }
                label={museOptionsLabelNodes[optionKey]}
                classes={{
                  label: styles.radio_label,
                }}
              />
            ))}
          </RadioGroup>
        </div>

        {compatibleMuseText && (
          <div className={styles.compatible_muse_text}>
            {compatibleMuseText}
          </div>
        )}

        <div className={styles.btn_container}>
          <button
            onClick={onContinueBtnClick}
            type="button"
            className={
              selectedMuseOption === undefined ? styles.disabled : undefined
            }
          >
            <span>Continue</span>
          </button>
        </div>
      </div>
    );

    const renderNewPaymentOptionsStep = () => {
      let colorCounter = 0;
      return (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              // marginBottom: 40,
            }}
          >
            <div style={{ maxWidth: 720 }}>
              <div
                className={styles.new_payment_title}
                style={isReactivationFlow ? { marginBottom: -20 } : null}
              >
                {// eslint-disable-next-line no-nested-ternary
                userId
                  ? 'Adding a family member'
                  : isReactivationFlow || shouldShowFamilyCorePackages
                  ? ''
                  : 'Free shipping and 30-day money-back guarantee'}
                {userId && (
                  <div style={{ fontWeight: 400, fontSize: 16 }}>
                    30-day money-back guarantee
                  </div>
                )}
              </div>
              {/* {isSwitchDisabled ? null : (
                <div className={styles.new_payment_switch_container}>
                  <div
                    className={styles.new_payment_switch_item}
                    style={togglePayUpfront ? { fontWeight: 900 } : null}
                  >
                    Pay upfront{' '}
                    {window.innerWidth < 768 && (
                      <br className={styles.mobile_break} />
                    )}
                    <span style={{ color: 'rgb(75, 224, 208)' }}>
                      {' '}
                      (save up to{' '}
                      {plans[initialPlanType].upfrontDiscountPercentage}%)
                    </span>
                  </div>

                  <Switch
                    onColor="#C3E0FF"
                    offColor="#C3E0FF"
                    offHandleColor="#0079FA"
                    onHandleColor="#0079FA"
                    onChange={() => {
                      setTogglePayUpfront(!togglePayUpfront);
                    }}
                    checked={!togglePayUpfront}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={14}
                    width={28}
                    className={styles.switch}
                  />
                  <div
                    className={
                      window.innerWidth < 768
                        ? styles.new_payment_switch_item_mobile
                        : styles.new_payment_switch_item
                    }
                    style={!togglePayUpfront ? { fontWeight: 900 } : null}
                  >
                    Pay monthly
                  </div>
                </div>
              )} */}
              {isSwitchDisabled ? null : (
                <div className={styles.new_payment_new_switch_container}>
                  <div
                    style={
                      togglePayUpfront
                        ? {
                            fontWeight: 700,
                            backgroundColor: '#4BE0D0',
                            color: 'white',
                          }
                        : null
                    }
                    onClick={() => {
                      setTogglePayUpfront(true);
                    }}
                    className={styles.new_payment_new_switch_item}
                  >
                    Pay upfront
                  </div>
                  <div
                    style={
                      !togglePayUpfront
                        ? {
                            fontWeight: 700,
                            backgroundColor: '#4BE0D0',
                            color: 'white',
                          }
                        : null
                    }
                    onClick={() => {
                      setTogglePayUpfront(false);
                    }}
                    className={styles.new_payment_new_switch_item}
                  >
                    Pay monthly
                  </div>
                </div>
              )}
              <div className={styles.new_payment_plans_container}>
                {// eslint-disable-next-line consistent-return, array-callback-return
                plans[initialPlanType].planOptions.map((planOption, index) => {
                  if (
                    (togglePayUpfront &&
                      planOption.billingPeriodInMonths > 1) ||
                    (!togglePayUpfront &&
                      planOption.billingPeriodInMonths === 1)
                  ) {
                    const packagePlan = {
                      bundleName: isReactivationFlow
                        ? plans[initialPlanType].planTypeName
                        : `${planOption.commitmentInMonths} Month Membership`,
                      planDescription: '',
                      annualMonthlyBasePrice: Math.round(
                        planOption.price / planOption.billingPeriodInMonths
                      ),
                      basePrice: planOption.price,
                      features: planOption.benefits,
                    };

                    colorCounter += 1;

                    const planToIterate = plans[
                      initialPlanType
                    ].planOptions.filter(
                      option =>
                        (togglePayUpfront &&
                          option.billingPeriodInMonths > 1) ||
                        (!togglePayUpfront &&
                          option.billingPeriodInMonths === 1)
                    );
                    const longestPeriod = Math.max(
                      ...planToIterate.map(option => option.commitmentInMonths)
                    );
                    let mostExpensivePlan;
                    let currentPlanPriceDiscount;
                    if (togglePayUpfront) {
                      mostExpensivePlan = Math.max(
                        ...planToIterate.map(
                          option =>
                            longestPeriod *
                            Math.round(option.price / option.commitmentInMonths)
                        )
                      );
                      currentPlanPriceDiscount =
                        mostExpensivePlan -
                        Math.round(
                          planOption.price / planOption.commitmentInMonths
                        ) *
                          longestPeriod;
                    } else {
                      mostExpensivePlan = Math.max(
                        ...planToIterate.map(
                          option => longestPeriod * option.price
                        )
                      );
                      currentPlanPriceDiscount =
                        mostExpensivePlan - planOption.price * longestPeriod;
                    }

                    return (
                      <div style={{ margin: 0 }}>
                        <NonfPackagePlan
                          key={planOption.stripeSubId}
                          packagePlan={packagePlan}
                          // eslint-disable-next-line no-undef
                          color={
                            packagePlansColors[
                              colorCounter % packagePlansColors.length
                            ]
                          }
                          isAnnual={togglePayUpfront}
                          onPackageSelected={() => {
                            // eslint-disable-next-line no-nested-ternary
                            const newPlanTypeToReview = userId
                              ? planTypes.familyMemberPlans
                              : isReactivationFlow
                              ? planTypes.continuityPlans
                              : planTypes.corePlans;
                            setSelectedPlanId(planOption.stripeSubId);
                            setSelectedPlanType(newPlanTypeToReview);
                            logMTRSubscriptionAppEvent({
                              name: 'on_payment_method_change',
                              data: {
                                newPlanTypeToReview,
                                planBillingPeriodInMonths:
                                  planOption.billingPeriodInMonths,
                              },
                            });
                            sendAmplitudeEvent('on_payment_method_change', {
                              newPlanTypeToReview,
                              planBillingPeriodInMonths:
                                planOption.billingPeriodInMonths,
                            });
                            onContinueBtnClick();
                          }}
                          packageIndex={index}
                          commitment={
                            // eslint-disable-next-line no-nested-ternary
                            isReactivationFlow
                              ? ' '
                              : !togglePayUpfront
                              ? `${planOption.commitmentInMonths}-month commitment`
                              : `Pay $${planOption.price} upfront`
                          }
                          discountAmount={currentPlanPriceDiscount}
                          isMostPopular={planOption.isMostPopular}
                        />
                      </div>
                    );
                  }
                })}
              </div>
              {plans[initialPlanType].generalBenefits ? (
                <div className={styles.general_benifits_container}>
                  <div className={styles.general_benifits_title}>
                    Membership includes:
                  </div>
                  <div className={styles.general_benifits_items}>
                    {plans[initialPlanType].generalBenefits.map(benefit => {
                      return (
                        <TooltipWrapper
                          title={
                            <TooltipTitle>{benefit.description}</TooltipTitle>
                          }
                        >
                          <div className={styles.general_benifits_item}>
                            <CheckIcon
                              style={{ marginRight: 10, color: '#4BE0D0' }}
                            />
                            {benefit.feature}
                          </div>
                        </TooltipWrapper>
                      );
                    })}
                  </div>
                </div>
              ) : null}
              {isReactivationFlow ? (
                <div
                  style={{
                    textAlign: 'center',
                    marginBottom: 10,
                    marginTop: 20,
                  }}
                >
                  No commitment, cancel anytime!
                </div>
              ) : (
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 900,
                    marginBottom: 30,
                    marginTop: 30,
                    fontSize: 13,
                  }}
                  // className={styles.responsive_text_container}
                >
                  *Renews with no Neuro Coach calls, billed monthly, and can be
                  canceled anytime.{' '}
                  <span
                    className={styles.text_link}
                    onClick={() => {
                      expandContinuityQuestionsFaqs();
                      scrollTo('faqs', 200);
                    }}
                  >
                    {window.innerWidth < 768 && (
                      <br className={styles.mobile_break} />
                    )}
                    Learn more
                  </span>
                </div>
              )}
              {isReactivationFlow ? null : (
                <div className={styles.new_payment_banner}>
                  <div>
                    To use Myndlift, you’ll need a Muse headband
                    <span
                      style={{
                        color: '#4be0d0',
                        cursor: 'pointer',
                        // marginLeft: 10,
                        fontSize: 14,
                      }}
                      onClick={() => {
                        setDisplayMuseModal(true);
                      }}
                    >
                      {' '}
                      {window.innerWidth < 768 && (
                        <br className={styles.mobile_break} />
                      )}
                      What’s that?
                    </span>
                  </div>
                  <img src={selectPlanMuse} style={{ width: 87, height: 67 }} />
                </div>
              )}

              <div
                className={styles.dollar_note}
                style={{ textAlign: 'center', marginBottom: 40 }}
              >
                Note: all prices shown are in USD
              </div>
            </div>
          </div>
          {/* {renderFAQS()} */}
        </div>
      );
    };
    const renderPaymentOptionsStep = () => {
      const packageName = shouldShowFamilyCorePackages
        ? 'Core Training (Family Member)'
        : 'Core Training';
      const currentPrimaryPlanOptions = shouldShowFamilyCorePackages
        ? familyPlanOptions
        : corePlanOptions;
      // const currentPlan = plans[selectedPlanType].planOptions.find(
      //   plan => plan.stripeSubId === selectedPlanId
      // );
      // const currentPlanPricePerWeek = getPlanPriceByWeek({
      //   price: currentPlan.price,
      //   billingPeriodInMonths: currentPlan.billingPeriodInMonths,
      // });

      return (
        <div className={styles.training_programs_root}>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              marginBottom: 25,
            }}
          >
            <div style={{ fontWeight: 900, fontSize: 20, marginBottom: 5 }}>
              You seem like a great fit!
            </div>
            <div>
              Choose your preferred plan below to begin your journey for better
              focus and wellbeing{' '}
            </div>
          </div> */}
          {optionalUserId && (
            <p
              className={styles.text_link}
              onClick={onChangeFamilyFlowHardwareSharingStatus}
              style={{ display: 'none' }}
            >
              {userId
                ? 'Want to include new hardware? See options'
                : 'Don’t need new hardware to be included? See options'}
            </p>
          )}
          {/* {!userId && !isReactivationFlow && (
            <div className={styles.note_container}>
              <p>
                Note: You’ll have the option to add a Muse device on the next
                screen
              </p>
            </div>
          )} */}
          <div className={styles.training_programs}>
            <div className={styles.core_training}>
              <div
                className={classNames(styles.program_text, {
                  [styles.small_padding]: !!userId,
                })}
              >
                {/* <span>Monthly/Quarterly/Biannually</span> */}
                {/* {!isReactivationFlow && !shouldShowFamilyCorePackages ? (
                  <span>Free shipping + 30-day money-back guarantee</span>
                ) : null} */}
              </div>
              <div className={styles.choose_plan_title}>{packageName}</div>
              <div className={styles.program_content}>
                {/* {isReactivationFlow ? (
                  <>
                    <p>
                      Your own custom training, 1 monthly Neuro Coach session,
                      reports, and a live support team.
                    </p>
                    <p className={styles.high_lighted_note}>
                      Does not include new hardware
                    </p>
                  </>
                ) : userId ? (
                  <>
                    <p>
                      Your own custom training, 1 monthly Neuro Coach session,
                      reports, and a live support team.
                    </p>
                    <p className={styles.high_lighted_note}>
                      Does not include new hardware
                    </p>
                  </>
                ) : (
                  <p>
                    Includes the Myndlift training kit, your own custom
                    training, 1 monthly Neuro Coach session, reports, and a live
                    support team.
                  </p>
                )} */}
                <div className={styles.choose_plan_perks}>
                  <CheckIcon style={{ marginRight: 10, color: '#4BE0D0' }} />
                  Guidance and supervision from your Neuro Coach (+ monthly
                  call)
                </div>
                <div className={styles.choose_plan_perks}>
                  <CheckIcon style={{ marginRight: 10, color: '#4BE0D0' }} />
                  Personalized training program
                </div>
                <div className={styles.choose_plan_perks}>
                  <CheckIcon style={{ marginRight: 10, color: '#4BE0D0' }} />
                  Recurring brain health checks
                </div>
                {userId ? null : (
                  <div className={styles.choose_plan_perks}>
                    <CheckIcon style={{ marginRight: 10, color: '#4BE0D0' }} />
                    Myndlift Kit
                  </div>
                )}
                <div className={styles.choose_plan_perks}>
                  <CheckIcon style={{ marginRight: 10, color: '#4BE0D0' }} />
                  Premium support from your Care Team
                </div>
                {userId ? null : (
                  <div className={styles.choose_plan_perks}>
                    <CheckIcon style={{ marginRight: 10, color: '#4BE0D0' }} />
                    Free shipping and 30-day money-back guarantee
                  </div>
                )}
                <div className={styles.perks_divider} />
                <div className={styles.payment_options}>
                  {currentPrimaryPlanOptions.map(plan => {
                    const { billingPeriodInMonths } = plan;
                    const planNameBasedOnBillingPeriod = getPlanTypeByBillingPeriodInMonths(
                      {
                        billingPeriodInMonths,
                      }
                    );
                    // const planPricePerWeek = getPlanPriceByWeek({
                    //   billingPeriodInMonths,
                    //   price: plan.price,
                    // });

                    const planSavings = getPlanSaving({
                      billingPeriodInMonths,
                      price: plan.price,
                      monthlyPlanPrice: currentPrimaryPlanOptions[0].price,
                    });

                    const planSavingsPercentage = getPlanSavingPercentage({
                      billingPeriodInMonths,
                      price: plan.price,
                      monthlyPlanPrice: currentPrimaryPlanOptions[0].price,
                    });

                    return (
                      <div
                        className={styles.payment_option}
                        key={plan.stripeSubId}
                      >
                        {isReactivationFlow ? (
                          <div>
                            <p>
                              {billingPeriodInMonths === 1
                                ? 'Paid Monthly'
                                : `Paid Every ${billingPeriodInMonths} Months`}{' '}
                              {planSavings > 0 && !couponDiscount.amount && (
                                <span className={styles.strong_text}>
                                  -{planSavingsPercentage}%
                                </span>
                              )}
                            </p>
                            <span>
                              <span>
                                ${plan.price} {planNameBasedOnBillingPeriod}{' '}
                              </span>

                              {planSavings > 0 && !couponDiscount.amount && (
                                <span
                                  className={styles.strong_text}
                                  style={{ marginLeft: 5 }}
                                >
                                  Save ${planSavings}
                                </span>
                              )}
                            </span>
                          </div>
                        ) : (
                          <div>
                            <p>
                              {billingPeriodInMonths === 1
                                ? 'Paid Monthly'
                                : `Paid Every ${billingPeriodInMonths} Months`}{' '}
                              {planSavings > 0 && (
                                <span className={styles.strong_text}>
                                  -{planSavingsPercentage}%
                                </span>
                              )}
                            </p>
                            <span>
                              ${plan.price} {planNameBasedOnBillingPeriod}{' '}
                              {planSavings > 0 && (
                                <span
                                  className={styles.strong_text}
                                  style={{ marginLeft: 5 }}
                                >
                                  Save ${planSavings}
                                </span>
                              )}
                            </span>
                          </div>
                        )}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Radio
                            classes={{
                              checked: styles.radio_checked,
                              root: styles.radio_root,
                            }}
                            checked={selectedPlanId === plan.stripeSubId}
                            onClick={() => {
                              const newPlanTypeToReview = shouldShowFamilyCorePackages
                                ? planTypes.familyMemberPlans
                                : planTypes.corePlans;
                              setSelectedPlanId(plan.stripeSubId);
                              setSelectedPlanType(newPlanTypeToReview);
                              sendAmplitudeEvent('on_payment_method_change', {
                                newPlanTypeToReview,
                                planBillingPeriodInMonths:
                                  plan.billingPeriodInMonths,
                              });
                              logMTRSubscriptionAppEvent({
                                name: 'on_payment_method_change',
                                data: {
                                  newPlanTypeToReview,
                                  planBillingPeriodInMonths:
                                    plan.billingPeriodInMonths,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.btn_container}>
                  <button
                    type="button"
                    onClick={onContinueBtnClick}
                    className={classNames({
                      [styles.disabled]:
                        selectedPlanType !== planTypes.corePlans &&
                        selectedPlanType !== planTypes.familyMemberPlans,
                    })}
                    style={{ width: '100%' }}
                  >
                    <span>Continue</span>
                  </button>
                </div>
                {isReactivationFlow && isEligibleForContinuityPlan ? null : (
                  <div className={styles.bellow_btn}>
                    <span aria-label="Rocket" role="img">
                      🚀
                    </span>{' '}
                    After 3 months of Core Training, you may switch to train
                    independently with no included Neuro Coach sessions for $
                    {continuityPlanOptions[0].price}/mo.{' '}
                    <span
                      className={styles.text_link}
                      onClick={() => {
                        expandContinuityQuestionsFaqs();
                        scrollTo('faqs', 200);
                      }}
                    >
                      Learn more
                    </span>
                  </div>
                )}
              </div>
            </div>

            {isReactivationFlow && isEligibleForContinuityPlan && (
              <div
                className={classNames(styles.continuity_training, {
                  [styles.wide]: isReactivationFlow,
                })}
              >
                <div className={styles.program_text}>
                  <span>Continuity Training</span>
                  <span>
                    ${monthlyContinuityPlanPricePerWeek}/
                    <span className={styles.weak_text}>week</span>
                  </span>
                </div>
                <div className={styles.program_content}>
                  <p>
                    Maintain your gains and continue your training with
                    on-demand Neuro Coach consultations.
                  </p>
                  {isReactivationFlow && isEligibleForContinuityPlan ? (
                    <>
                      <div className={styles.payment_options}>
                        {continuityPlanOptions.map(plan => {
                          const { billingPeriodInMonths } = plan;
                          const planNameBasedOnBillingPeriod = getPlanTypeByBillingPeriodInMonths(
                            {
                              billingPeriodInMonths,
                            }
                          );
                          // const planPricePerWeek = getPlanPriceByWeek({
                          //   billingPeriodInMonths,
                          //   price: plan.price,
                          // });

                          const planSavings = getPlanSaving({
                            billingPeriodInMonths,
                            price: plan.price,
                            monthlyPlanPrice: continuityPlanOptions[0].price,
                          });

                          const planSavingsPercentage = getPlanSavingPercentage(
                            {
                              billingPeriodInMonths,
                              price: plan.price,
                              monthlyPlanPrice: continuityPlanOptions[0].price,
                            }
                          );

                          return (
                            <div
                              className={styles.payment_option}
                              key={plan.stripeSubId}
                            >
                              <div>
                                <p>
                                  {billingPeriodInMonths === 1
                                    ? 'Paid Monthly'
                                    : `Paid Every ${billingPeriodInMonths} Months`}{' '}
                                  {planSavings > 0 &&
                                    !couponDiscount.amount && (
                                      <span className={styles.strong_text}>
                                        -{planSavingsPercentage}%
                                      </span>
                                    )}
                                </p>
                                <span>
                                  <span>
                                    ${plan.price} {planNameBasedOnBillingPeriod}{' '}
                                  </span>

                                  {planSavings > 0 && !couponDiscount.amount && (
                                    <span
                                      className={styles.strong_text}
                                      style={{ marginLeft: 5 }}
                                    >
                                      Save ${planSavings}
                                    </span>
                                  )}
                                </span>
                              </div>
                              <Radio
                                classes={{
                                  checked: styles.radio_checked,
                                  root: styles.radio_root,
                                }}
                                checked={selectedPlanId === plan.stripeSubId}
                                onClick={() => {
                                  setSelectedPlanId(plan.stripeSubId);
                                  setSelectedPlanType(
                                    planTypes.continuityPlans
                                  );
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                      <div className={styles.btn_container}>
                        <button
                          type="button"
                          onClick={onContinueBtnClick}
                          className={classNames(styles.blue, {
                            [styles.disabled]:
                              selectedPlanType !== planTypes.continuityPlans,
                          })}
                        >
                          <span>Continue</span>
                        </button>
                      </div>
                    </>
                  ) : (
                    <p className={styles.note}>
                      Available only after a minimum 3 months of Core Training.{' '}
                      <span
                        className={styles.text_link}
                        onClick={() => {
                          expandContinuityQuestionsFaqs();
                          scrollTo('faqs', 200);
                        }}
                      >
                        Learn more
                      </span>
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className={styles.note_prices_in_usd}>
            <p>
              {`${
                shouldShowFamilyCorePackages || isReactivationFlow
                  ? ''
                  : 'Free shipping and'
              }`}{' '}
              {isReactivationFlow || shouldShowFamilyCorePackages
                ? ''
                : '30-day money-back guarantee'}
            </p>
            <p>Note: all prices shown are in USD</p>
          </div>

          {userId || isReactivationFlow ? renderFAQS() : null}
        </div>
      );
    };

    const renderPaymentOptionsStepBVariant = () => {
      const benefitsTexts = [
        'Personalized training program and recurring brain health checks',
        <span>
          Monthly Neuro Coach sessions included.{' '}
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              expandNCSessionQuestionsFaqs();
              scrollTo('faqs', 200);
            }}
          >
            What’s that?
          </span>
        </span>,
        'Myndlift accessories + premium support',
      ];

      const journeySteps = [
        'Get the kit',
        'Run a brain health check',
        'Meet with the Neuro Coach to set up the program',
        'Start your personalized training',
        'Meet your Neuro Coach on a monthly basis',
      ];

      const currentPrimaryPlanOptions = shouldShowFamilyCorePackages
        ? familyPlanOptions
        : corePlanOptions;

      const filteredReviews = reviews.filter(
        review => review.reviewMessage.length < 350
      );

      // eslint-disable-next-line array-callback-return, consistent-return
      const sortedReviews = filteredReviews.sort((a, b) => {
        const keyWords = {
          focus: ['attention', 'focus'],
          performance: ['performance'],
          anxiety: ['relax', 'anxious', 'quiter'],
        };
        const includesString = (arr, s) => {
          for (let i = 0; i < arr.length; i += 1) {
            if (s.includes(arr[i])) {
              return true;
            }
          }
          return false;
        };

        if (
          isImproveFocus &&
          includesString(keyWords.focus, a.reviewMessage.toLowerCase())
        ) {
          return -1;
        }
        if (
          isImproveFocus &&
          includesString(keyWords.focus, b.reviewMessage.toLowerCase())
        ) {
          return 1;
        }
        if (
          isPeakPerformance &&
          includesString(keyWords.performance, a.reviewMessage.toLowerCase())
        ) {
          return -1;
        }
        if (
          isPeakPerformance &&
          includesString(keyWords.performance, b.reviewMessage.toLowerCase())
        ) {
          return 1;
        }
        if (
          isReduceAnxiety &&
          includesString(keyWords.anxiety, a.reviewMessage.toLowerCase())
        ) {
          return -1;
        }
        if (
          isReduceAnxiety &&
          includesString(keyWords.anxiety, b.reviewMessage.toLowerCase())
        ) {
          return 1;
        }
      });

      const calcTitle = () => {
        if (
          eligibilityQuestions &&
          eligibilityQuestions.reasonsForDoingNeuroFeedback
        ) {
          // const isGeneralWellbeing = eligibilityQuestions.reasonsForDoingNeuroFeedback.find(el => el === 'General wellbeing');
          if (isImproveFocus && isPeakPerformance && isReduceAnxiety) {
            return [
              'Your plan for peak performance is ready! 🚀',
              'Tailor-made program for your unique brain activity',
              'To help you achieve better focus, increase calm, and enhance performance in any task, Myndlift measures and optimizes your brain activity.',
            ];
          }
          if (isPeakPerformance && isReduceAnxiety) {
            return [
              'Your peak performance plan for reduced anxiety is ready 🧘‍♀️',
              'Tailor-made program for your unique brain activity',
              'To help you achieve calm and enhance performance in any task, Myndlift measures and optimizes your brain activity.',
            ];
          }
          if (isPeakPerformance && isImproveFocus) {
            return [
              'Your peak performance plan for improved focus is ready!  🚀',
              'Tailor-made focus program for your unique brain activity',
              'To help you achieve better focus and enhance performance in any task, Myndlift measures and optimizes your brain activity.',
            ];
          }
          if (isReduceAnxiety && isImproveFocus) {
            return [
              'Your plan for improved focus and reduced anxiety is ready!  🎯',
              'Tailor-made program for your unique brain activity',
              'To help you achieve focus and calm, Myndlift measures and optimizes your brain activity.',
            ];
          }
          if (isReduceAnxiety) {
            return [
              'Your program for reduced anxiety is ready 🧘‍♀️',
              'Tailor-made program for your unique brain activity',
              'To help you achieve calm, Myndlift measures and optimizes your brain activity.',
            ];
          }
          if (isPeakPerformance) {
            return [
              'Your plan for peak performance is ready! 🚀',
              'Tailor-made program for your unique brain activity',
              'To help you enhance performance in any task, Myndlift measures and optimizes your brain activity.',
            ];
          }
          if (isImproveFocus) {
            return [
              'Your plan for improved focus is ready! 🎯',
              'Tailor-made focus program for your unique brain activity',
              'To help you achieve better focus and concentration, Myndlift measures and optimizes your brain activity.',
            ];
          }
        }
        return [
          'Your plan for improved wellbeing is ready! 🧘‍♀️',
          'Tailor-made program for your unique brain activity',
          'To help you achieve better focus, increase calm, and improve overall wellbeing, Myndlift measures and optimizes your brain activity.',
        ];
      };

      return (
        <div className={styles.payment_option_b_container}>
          <div
            className={styles.payment_option_b_top_container}
            style={
              !isMatchingXSScreen && isMatchingMDScreen ? { padding: 20 } : null
            }
          >
            <div className={styles.payment_option_b_top_left}>
              <img
                src={
                  // eslint-disable-next-line no-nested-ternary
                  isMatchingXXSScreen
                    ? womanPracticeMobileImage
                    : // eslint-disable-next-line no-nested-ternary
                    isMatchingXSScreen
                    ? womanPracticeMobileImage1
                    : isMatchingMDScreen
                    ? womanPracticeTabletImage
                    : womanPracticeImage
                }
                className={styles.payment_option_b_top_image}
                style={
                  // eslint-disable-next-line no-nested-ternary
                  isMatchingXSScreen
                    ? null
                    : // eslint-disable-next-line no-nested-ternary
                    isMatchingMDScreen
                    ? { width: 295, height: 475 }
                    : { width: 464, height: 441 }
                }
              />
              <div
                className={styles.image_clicked}
                style={
                  // eslint-disable-next-line no-nested-ternary
                  isMatchingXSScreen
                    ? { top: 0, width: '104%', height: 141, right: -2 }
                    : // eslint-disable-next-line no-nested-ternary
                    isMatchingMDScreen
                    ? {
                        width: 295,
                        height: 475,
                        left: 0,
                        top: 0,
                        borderRadius: 30,
                      }
                    : {
                        width: 448,
                        height: 425,
                        left: 8,
                        top: 5,
                        borderRadius: 32,
                      }
                }
                onClick={() => {
                  sendAmplitudeEvent('subscription_how_it_works_clicked');
                  logMTRSubscriptionAppEvent({
                    name: 'subscription_how_it_works_clicked',
                  });
                  setDisplayVideoGuide(Date.now());
                }}
              >
                <div
                  style={
                    // eslint-disable-next-line no-nested-ternary
                    isMatchingXXSScreen
                      ? {
                          position: 'relative',
                          right: -30,
                          color: '#6A6A6A',
                          fontWeight: 900,
                          fontSize: 14,
                        }
                      : isMatchingXSScreen
                      ? {
                          position: 'relative',
                          right: -50,
                          color: '#6A6A6A',
                          fontWeight: 900,
                          fontSize: 14,
                        }
                      : null
                  }
                >
                  How it works
                </div>
                <div
                  style={
                    // eslint-disable-next-line no-nested-ternary
                    isMatchingXXSScreen
                      ? { position: 'relative', right: -30 }
                      : isMatchingXSScreen
                      ? { position: 'relative', right: -50 }
                      : null
                  }
                  className={styles.play_img_container}
                >
                  {/* <PlayCircleOutlineIcon style={{ fontSize: 50 }} /> */}
                  <img src={PlayImage} className={styles.play_img} />
                </div>
              </div>

              <div
                className={styles.payment_option_icon_benefits}
                style={{ marginTop: 0 }}
              >
                <div className={styles.payment_option_icon_benefit_container}>
                  <ShippingIcon />
                  <div className={styles.payment_option_icon_benefit_text}>
                    Free shipping
                  </div>
                </div>
                <div className={styles.payment_option_icon_benefit_container}>
                  <StarsIcon />
                  <div className={styles.payment_option_icon_benefit_text}>
                    25,000 members served
                  </div>
                </div>
                <div className={styles.payment_option_icon_benefit_container}>
                  <MoneyIcon />
                  <div className={styles.payment_option_icon_benefit_text}>
                    30-day money back
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.payment_option_b_top_right}>
              <div
                className={styles.payment_option_b_title}
                style={{ marginBottom: 10 }}
              >
                {calcTitle()[0]}
              </div>
              {benefitsTexts.map(text => (
                <div className={styles.payment_option_benefit_container}>
                  <CheckIcon style={{ marginRight: 10, color: '#4BE0D0' }} />
                  <div>{text}</div>
                </div>
              ))}
              <div className={styles.payment_option_select_option_container}>
                {currentPrimaryPlanOptions.map(plan => {
                  const { billingPeriodInMonths } = plan;

                  const planSavings = getPlanSaving({
                    billingPeriodInMonths,
                    price: plan.price,
                    monthlyPlanPrice: currentPrimaryPlanOptions[0].price,
                  });

                  // const planSavingsPercentage = getPlanSavingPercentage({
                  //   billingPeriodInMonths,
                  //   price: plan.price,
                  //   monthlyPlanPrice: currentPrimaryPlanOptions[0].price,
                  // });

                  return (
                    <div
                      className={styles.payment_option_b_variant}
                      key={plan.stripeSubId}
                      onClick={() => {
                        const newPlanTypeToReview = shouldShowFamilyCorePackages
                          ? planTypes.familyMemberPlans
                          : planTypes.corePlans;
                        setSelectedPlanId(plan.stripeSubId);
                        setSelectedPlanType(newPlanTypeToReview);
                        sendAmplitudeEvent('on_payment_method_change', {
                          newPlanTypeToReview,
                          planBillingPeriodInMonths: plan.billingPeriodInMonths,
                        });
                        logMTRSubscriptionAppEvent({
                          name: 'on_payment_method_change',
                          data: {
                            newPlanTypeToReview,
                            planBillingPeriodInMonths:
                              plan.billingPeriodInMonths,
                          },
                        });
                      }}
                      style={
                        selectedPlanId === plan.stripeSubId
                          ? {
                              border: '2px solid #16E0D0',
                              marginTop: 23,
                            }
                          : {
                              border: '1px solid #CBCBCB',
                              marginTop: 23,
                            }
                      }
                    >
                      <div className={styles.price_b_text}>
                        <p style={{ fontWeight: 900, fontSize: 16 }}>
                          {// eslint-disable-next-line no-nested-ternary
                          billingPeriodInMonths === 1
                            ? 'Monthly'
                            : `${billingPeriodInMonths} month program`}
                        </p>
                        {planSavings > 0 && (
                          <div className={styles.price_b_text_discount}>
                            Save ${planSavings}
                          </div>
                        )}
                      </div>

                      <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div className={styles.price_right}>
                            {/* {beforeDiscountPlan ? (
                                <span
                                  style={{
                                    fontSize: 16,
                                    textDecoration: 'line-through',
                                    marginRight: 15,
                                  }}
                                >
                                  $
                                  {
                                    beforeDiscountPlan[
                                      shouldShowFamilyCorePackages
                                        ? 'familyMemberPlans'
                                        : 'corePlans'
                                    ].planOptions[index].price
                                  }
                                </span>
                              ) : null} */}
                            <span style={{ fontWeight: 900, fontSize: 16 }}>
                              {billingPeriodInMonths === 1
                                ? `$${plan.price}/mo`
                                : `$${plan.price}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className={styles.contiuity_price_banner}>
                  <LockOpenIcon
                    style={{
                      marginRight: 10,
                      position: 'relative',
                      top: isMatchingXXSScreen ? 0 : 5,
                    }}
                  />
                  You can switch to ${continuityPlanOptions[0].price}/mo after 3
                  months. Cancel anytime.
                </div>
                <div className={styles.checkbox_and_text}>
                  <Checkbox
                    style={
                      isMatchingXSScreen
                        ? { color: '#16E0D0', paddingLeft: 0 }
                        : { color: '#16E0D0' }
                    }
                    variant="contained"
                    onChange={e => {
                      if (e.target.checked) {
                        setSelectedMuseOption(museOptions.includeAMuseDevice);
                      } else {
                        setSelectedMuseOption(
                          museOptions.iAlreadyOwnAMuseDevice
                        );
                      }
                      // eslint-disable-next-line no-undef
                      ga('send', {
                        hitType: 'event',
                        eventCategory: 'MTR',
                        eventAction: 'on_muse_option_changed',
                        eventLabel: e.target.checked,
                      });
                      sendAmplitudeEvent('on_muse_option_changed', {
                        is_selected: e.target.checked,
                      });
                    }}
                    checked={
                      selectedMuseOption === museOptions.includeAMuseDevice
                    }
                  />
                  I don’t have a Muse. Add it for $199.{' '}
                  <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => {
                      setDisplayMuseModal(true);
                      // eslint-disable-next-line no-undef
                      ga('send', {
                        hitType: 'event',
                        eventCategory: 'MTR',
                        eventAction: 'muse_learn_more_clicked',
                        eventLabel: '',
                      });
                      sendAmplitudeEvent('muse_learn_more_clicked');
                    }}
                  >
                    What’s that?
                  </span>
                </div>
                <span className={styles.hide_btn}>
                  <div
                    className={styles.btn_container_b_screen}
                    onClick={onContinueBtnClick}
                  >
                    <span>Continue</span>
                  </div>
                </span>
              </div>
            </div>
            {isMatchingXSScreen ? (
              <div className={styles.payment_option_icon_benefits_mobile}>
                <div className={styles.payment_option_icon_benefit_container}>
                  <ShippingIcon />
                  <div className={styles.payment_option_icon_benefit_text}>
                    Free shipping
                  </div>
                </div>
                <div className={styles.payment_option_icon_benefit_container}>
                  <StarsIcon />
                  <div className={styles.payment_option_icon_benefit_text}>
                    25,000 members served
                  </div>
                </div>
                <div className={styles.payment_option_icon_benefit_container}>
                  <MoneyIcon />
                  <div className={styles.payment_option_icon_benefit_text}>
                    30-day money back
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {/* {ABPricingToggle ? (
            <div className={styles.neurocoach_section_container}>
              <div className={styles.neurocoach_section_container_small}>
                <div
                  className={styles.option_b_benefits_title}
                  style={{ marginTop: 30 }}
                >
                  Meet some of our Neuro Coaches
                </div>
                <div style={{ marginBottom: 20 }}>
                  You will be automatically matched with a Neuro Coach based on
                  their availability and expertise.
                </div>
                <div
                  className={
                    imageLoaded && toggleNeurocoachAnimation
                      ? styles.slide_in_right
                      : null
                  }
                  style={!imageLoaded ? { visibility: 'hidden' } : null}
                >
                  <div className={styles.neurocoach_section_content}>
                    <img
                      src={
                        isMatchingXSScreen
                          ? remoteClinicsInfo[
                              sortedFilteredNeuroCoachesSectionData[
                                neuroCoachSelectedIndex
                              ]
                            ].subscriptionPageCard.info.mobileImage
                          : remoteClinicsInfo[
                              sortedFilteredNeuroCoachesSectionData[
                                neuroCoachSelectedIndex
                              ]
                            ].subscriptionPageCard.info.image
                      }
                      className={styles.neurocoaches_img}
                      onLoad={handleImageLoad}
                    />
                    <div>
                      <div className={styles.neurocoach_section_quote}>
                        {
                          remoteClinicsInfo[
                            sortedFilteredNeuroCoachesSectionData[
                              neuroCoachSelectedIndex
                            ]
                          ].subscriptionPageCard.info.description
                        }
                      </div>
                      <div className={styles.neurocoach_section_name}>
                        -
                        {
                          remoteClinicsInfo[
                            sortedFilteredNeuroCoachesSectionData[
                              neuroCoachSelectedIndex
                            ]
                          ].subscriptionPageCard.info.name
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.neurocoach_dots_container}>
                  {sortedFilteredNeuroCoachesSectionData.map((id, idx) => (
                    <div
                      onClick={() => {
                        setNeuroCoachSelectedIndex(idx);
                        sendEvents('mtr_subscription_neurocoach_selected', {
                          index: idx,
                        });
                      }}
                      className={styles.neurocoach_dot}
                      style={
                        neuroCoachSelectedIndex === idx
                          ? { color: '#4be0d0' }
                          : null
                      }
                    >
                      ⬤
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null} */}
          <span
            className={styles.banner_and_benefits_mobile_container}
            style={
              !isMatchingXSScreen && isMatchingMDScreen ? { padding: 20 } : null
            }
          >
            {!isMatchingXSScreen ? (
              <div className={styles.option_b_benefits}>
                <div className={styles.option_b_benefits_title}>
                  {calcTitle()[1]}
                </div>
                <div>{calcTitle()[2]}</div>
              </div>
            ) : null}
            <div className={styles.option_b_benefits_and_image_container}>
              <div className={styles.option_b_benefits_container}>
                <div className={styles.option_b_benefits_small_title}>
                  Your personalized Myndlift journey…
                </div>
                <span className={styles.option_b_benefits_svg}>
                  <StepsIcon />
                </span>
                {journeySteps.map(step => (
                  <div className={styles.option_b_benefit_item}>{step}</div>
                ))}
              </div>
              {isMatchingXSScreen ? (
                <div className={styles.option_b_benefits}>
                  <div className={styles.option_b_benefits_title}>
                    {calcTitle()[1]}
                  </div>
                  <div>{calcTitle()[2]}</div>
                </div>
              ) : null}
              <img
                src={
                  // eslint-disable-next-line no-nested-ternary
                  isMatchingXSScreen
                    ? nerdWithHeadsetMobileImage
                    : isMatchingMDScreen
                    ? nerdWithHeadsetTabletImage
                    : nerdWithHeadsetImage
                }
                style={
                  // eslint-disable-next-line no-nested-ternary
                  isMatchingXSScreen
                    ? { width: '99vw' }
                    : isMatchingMDScreen
                    ? { width: 204, height: 354 }
                    : { width: 420, height: 373 }
                }
              />
            </div>
            <div className={styles.option_b_banner}>
              <div className={styles.option_b_banner_title}>
                30-day money-back guarantee
                <span style={{ position: 'relative', top: 5, left: 10 }}>
                  <WhiteMoneyIcon />
                </span>
              </div>
              <div className={styles.option_b_banner_description}>
                Your 30-day money-back guarantee begins on your first Neuro
                Coach consultation or the Myndlift Kit’s delivery, whatever
                arrives sooner. Not happy with Myndlift? Get a refund.
              </div>
            </div>
          </span>
          {sortedReviews.length && videoTestimonials ? (
            <ReviewsCarousel
              sortedReviews={sortedReviews}
              videoTestimonials={videoTestimonials}
              sendEvents={sendEvents}
              // isYoutubeDialogOpen={isYoutubeDialogOpen}
              setIsYoutubeDialogOpen={setIsYoutubeDialogOpen}
              setCurrentVideoDialogInfo={setCurrentVideoDialogInfo}
              countryFlags={countryFlags}
            />
          ) : null}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 100,
              padding: '0 20px',
            }}
          >
            {renderFAQS()}
          </div>
        </div>
      );
    };

    const renderPaymentOptionsStepSelector = () =>
      // eslint-disable-next-line no-nested-ternary
      shouldRenderNewPlans
        ? renderNewPaymentOptionsStep()
        : !(isReactivationFlow || shouldShowFamilyCorePackages)
        ? renderPaymentOptionsStepBVariant()
        : renderPaymentOptionsStep();

    const museOptionStepAnimationClass = (() => {
      if (prvStep === stepperSteps.shippingInfo) {
        return styles.slide_in_right;
      }
      return styles.slide_in_left;
    })();

    const paymentOptionsStepAnimationClass = (() => {
      if (prvCurrentStepForChoosePlan === choosePlanSteps.museOptions) {
        return styles.slide_in_right;
      }
      if (
        prvStep === stepperSteps.shippingInfo ||
        prvStep === stepperSteps.cart
      ) {
        return styles.slide_in_right;
      }

      return styles.slide_in_left;
    })();

    const currentAnimationClass =
      currentStepForChoosePlan === choosePlanSteps.museOptions
        ? museOptionStepAnimationClass
        : paymentOptionsStepAnimationClass;

    return (
      <div
        className={classNames(styles.choose_plan_step, currentAnimationClass)}
        // key is required for animations to work
        key={
          currentStepForChoosePlan === choosePlanSteps.museOptions
            ? 'museOptions'
            : 'paymentOptions'
        }
      >
        {currentStepForChoosePlan === choosePlanSteps.museOptions
          ? renderMuseOptionsStep()
          : renderPaymentOptionsStepSelector()}
        <MuseDialog
          isOpen={displayMuseModal}
          onClose={() => {
            setDisplayMuseModal(false);
          }}
          hideMuseHeadset={hideMuseHeadset}
        />
      </div>
    );
  };

  const renderShippingInfoStep = () => {
    const currentPlanDetails = getCurrentPlanDetails();
    const stepContainerAnimationClass =
      prvStep === stepperSteps.choosePlan
        ? styles.slide_in_left
        : styles.slide_in_right;
    return (
      <div className={stepContainerAnimationClass}>
        <div className={styles.shipping_info_step}>
          <div className={styles.inputs}>
            <TextField
              label="First name"
              error={!!shippingFormErrors.firstName}
              helperText={shippingFormErrors.firstName}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              value={shippingFormValues.firstName || ''}
              onChange={createOnTextChangeCallback('firstName')}
              inputProps={{
                name: 'firstName',
              }}
            />
            <TextField
              label="Last name"
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              onChange={createOnTextChangeCallback('lastName')}
              error={!!shippingFormErrors.lastName}
              value={shippingFormValues.lastName || ''}
              helperText={shippingFormErrors.lastName}
              inputProps={{
                name: 'lastName',
              }}
            />

            <TextField
              label="Email"
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              onChange={createOnTextChangeCallback('email')}
              error={!!shippingFormErrors.email}
              value={shippingFormValues.email || ''}
              helperText={shippingFormErrors.email}
              inputProps={{
                name: 'email',
              }}
            />

            <TextField
              label="Country"
              select
              name="Country"
              onChange={onCountryChange}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              fullWidth
              value={shippingFormValues.country}
              inputProps={{
                name: 'country',
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                classes: {
                  root: styles.select_country_root,
                },
              }}
            >
              {filteredCountries.map(country => (
                <option key={country.iso2} value={country.iso2}>
                  {country.name}
                </option>
              ))}
            </TextField>

            {shippingFormValues.country === 'us' ||
            shippingFormValues.country === 'ca' ? (
              <TextField
                // label="State"
                select
                name="State"
                onChange={onStateChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                fullWidth
                value={shippingFormValues.state}
                inputProps={{
                  name: 'state',
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  classes: {
                    root: styles.select_country_root,
                  },
                }}
                error={!!shippingFormErrors.state}
              >
                {(shippingFormValues.country === 'us'
                  ? usaStates
                  : canadaProvinces
                ).map(state => (
                  <option key={state.abbreviation} value={state.abbreviation}>
                    {state.name}
                  </option>
                ))}
              </TextField>
            ) : (
              <TextField
                label="State"
                variant="outlined"
                classes={{ root: styles.textfield }}
                fullWidth
                onChange={createOnTextChangeCallback('state')}
                error={!!shippingFormErrors.state}
                value={shippingFormValues.state || ''}
                helperText={shippingFormErrors.state}
                inputProps={{
                  name: 'state',
                }}
              />
            )}
            <TextField
              label="City"
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              onChange={createOnTextChangeCallback('city')}
              error={!!shippingFormErrors.city}
              value={shippingFormValues.city || ''}
              helperText={shippingFormErrors.city}
              inputProps={{
                name: 'city',
              }}
            />
            <TextField
              label="Street, P.O. Box, etc…"
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              onChange={createOnTextChangeCallback('street')}
              error={!!shippingFormErrors.street}
              value={shippingFormValues.street || ''}
              helperText={shippingFormErrors.street}
              inputProps={{
                name: 'street',
              }}
            />

            <TextField
              label="Apt, Floor, etc.. (optional)"
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              onChange={createOnTextChangeCallback('apt')}
              error={!!shippingFormErrors.apt}
              value={shippingFormValues.apt || ''}
              helperText={shippingFormErrors.apt}
              inputProps={{
                name: 'apt',
              }}
            />
            <TextField
              label="Zip/Postal Code"
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              onChange={createOnTextChangeCallback('zipCode')}
              error={!!shippingFormErrors.zipCode}
              value={shippingFormValues.zipCode || ''}
              helperText={shippingFormErrors.zipCode}
              inputProps={{
                name: 'zipCode',
              }}
            />
            {/* <TextField
              label="Phone"
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              onChange={createOnTextChangeCallback('phone')}
              error={!!shippingFormErrors.phone}
              value={shippingFormValues.phone || ''}
              helperText={shippingFormErrors.phone}
              inputProps={{
                name: 'phone',
              }}
              placeholder="Phone (e.g. +9171234567)"
            /> */}
            <PhoneInput
              country={shippingFormValues.country}
              value={shippingFormValues.phone || ''}
              onChange={createOnTextChangeCallback('phone')}
              inputProps={{
                name: 'phone',
              }}
              placeholder="Phone (e.g. +9171234567)"
              label="Phone"
              variant="outlined"
              inputClass={
                shippingFormErrors.phone
                  ? styles.phone_input_error
                  : styles.phone_input
              }
              fullWidth
              error={!!shippingFormErrors.phone}
              helperText={shippingFormErrors.phone}
            />
            {shippingFormErrors.phone ? (
              <span className={styles.phone_input_message}>
                {shippingFormErrors.phone}
              </span>
            ) : null}
          </div>
          <div className={styles.package}>
            <div className={styles.package_info}>
              <img
                src={
                  selectedMuseOption !== museOptions.includeAMuseDevice
                    ? packageWithoutMuseImage
                    : packageWithMuseImage
                }
              />
              <p
                className={styles.package_name}
                style={
                  shouldRenderNewPlans
                    ? { color: '#818181', fontSize: 17 }
                    : null
                }
              >
                {shouldRenderNewPlans
                  ? `${currentPlanDetails.commitmentInMonths} Month Membership (${currentPlanDetails.nameBasedOnNewPricing})`
                  : `Core Training (${currentPlanDetails.nameBasedOnBillingPeriod})`}
              </p>
              <p className={styles.package_description}>
                {shouldRenderNewPlans
                  ? `Includes ${currentPlanDetails.membershipNumCoachCalls} Neuro Coach calls, Myndlift Kit, Personalized Training Program, Live Support Team`
                  : 'Includes hardware kit, unlimited training sessions, and monthly Neuro Coach consultations.'}
              </p>
              <div className={styles.divider} />
              <p className={styles.kit_includes}>Myndlift Kit includes:</p>
              <p className={styles.kit_includes_content}>
                Hard case, electrode, paste, elastic tightener, pouch
              </p>
              {selectedMuseOption !== museOptions.includeAMuseDevice && (
                <p className={styles.kit_does_not_include_muse_headset}>
                  Muse headset is not included
                </p>
              )}
              {selectedMuseOption === museOptions.includeAMuseDevice && (
                <div>
                  <div className={styles.divider}> </div>
                  <p className={styles.kit_includes}>Add-on:</p>
                  <p className={styles.kit_includes_content}>Muse 2 Headband</p>
                </div>
              )}
            </div>
            <div className={styles.btn_container}>
              <button type="button" onClick={onContinueBtnClick}>
                <span>Continue</span>
              </button>
            </div>
            <div className={styles.continuity_training_info}>
              {// eslint-disable-next-line no-nested-ternary
              shouldRenderNewPlans ? (
                <p>
                  {`All plans will automatically renew at a rate of $${
                    plans[postMembershipPlan.planType].planOptions[
                      postMembershipPlan.planInd
                    ].price
                  }/mo, with no Neuro Coach calls included. Billed monthly, no commitment.`}
                </p>
              ) : selectedPlanId === corePlanOptions[0].stripeSubId ||
                selectedPlanId ===
                  (continuityPlanOptions &&
                    continuityPlanOptions[0].stripeSubId) ? (
                <p>
                  You can cancel your plan at anytime. Once you have trained for
                  3 months, you are eligible to switch to the lower-cost
                  Continuity Training plan.
                </p>
              ) : (
                <p>
                  At the end of the subscription period, you can choose to
                  switch to the lower-cost Continuity Training or renew/cancel
                  your plan.
                </p>
              )}

              {/* <p
                onClick={() => {
                  expandContinuityQuestionsFaqs();
                  scrollTo('faqs', 200);
                }}
              >
                {shouldRenderNewPlans
                  ? 'Learn more'
                  : 'What’s Continuity Training ?'}
              </p> */}
            </div>
          </div>
        </div>
        {/* {renderFAQS()} */}
      </div>
    );
  };

  const Cart = ({ buttonText = 'CONTINUE TO PAYMENT' } = {}) => {
    const currentPlanDetails = getCurrentPlanDetails();
    const totalDue = Math.max(
      0,
      (selectedMuseOption === museOptions.includeAMuseDevice
        ? museDevicePrice + currentPlanDetails.price
        : currentPlanDetails.price) - (isReactivationFlow ? extraCredit : 0)
    );

    return (
      <div className={styles.cart_container}>
        <div className={styles.cart}>
          <div className={styles.summary_info}>
            {shouldShowNewFlow ? null : <p>Summary</p>}
            <div>
              {// eslint-disable-next-line no-nested-ternary
              !shouldRenderNewPlans ? (
                <p>
                  {formattedPlanTypes[selectedPlanType]} (
                  {currentPlanDetails.nameBasedOnBillingPeriod})
                  {shouldShowNewFlow ? (
                    <EditIcon
                      onClick={onBackBtnClick}
                      style={{
                        color: '#4be0d0',
                        fontSize: 16,
                        cursor: 'pointer',
                        position: 'relative',
                        top: 4,
                        left: 5,
                      }}
                    />
                  ) : null}
                </p>
              ) : isReactivationFlow ? (
                <p>
                  {`${plans[initialPlanType].planTypeName}, ${currentPlanDetails.nameBasedOnNewPricing}`}
                </p>
              ) : (
                <p>
                  {`${currentPlanDetails.commitmentInMonths} Month Membership${
                    shouldShowFamilyCorePackages ? ' (Family member)' : ''
                  } (${currentPlanDetails.nameBasedOnNewPricing})`}
                </p>
              )}
              <p>${currentPlanDetails.price}</p>
            </div>
            {isReactivationFlow && extraCredit ? (
              <div>
                <p>Credit</p>
                <p>${extraCredit}</p>
              </div>
            ) : null}
            {selectedMuseOption === museOptions.includeAMuseDevice && (
              <div>
                <p>
                  Muse 2 Headband
                  {shouldShowNewFlow ? (
                    <EditIcon
                      onClick={onBackBtnClick}
                      style={{
                        color: '#4be0d0',
                        fontSize: 16,
                        cursor: 'pointer',
                        position: 'relative',
                        top: 4,
                        left: 5,
                      }}
                    />
                  ) : null}
                </p>
                <p>${museDevicePrice}</p>
              </div>
            )}
            {couponDiscount.amount > 0 && (
              <div>
                <p>
                  Coupon: {lastValidCouponCode}
                  {shouldShowNewFlow && !shouldDisplayCoupon ? (
                    <EditIcon
                      onClick={() => setShouldDisplayCoupon(true)}
                      style={{
                        color: '#4be0d0',
                        fontSize: 16,
                        cursor: 'pointer',
                        position: 'relative',
                        top: 4,
                        left: 5,
                      }}
                    />
                  ) : null}
                </p>
                <p>-${getDiscountedAmountBasedOnCoupon()}</p>
              </div>
            )}
            {shouldShowNewFlow && (
              <div>
                <p>Shipping</p>
                <p>FREE</p>
              </div>
            )}
            {shouldShowNewFlow && (
              <div>
                <p>Neuro Coach support</p>
                <p>Included</p>
              </div>
            )}
            {shouldShowNewFlow && (
              <div>
                <p>Myndlift kit</p>
                <p>Included</p>
              </div>
            )}
            {// eslint-disable-next-line no-nested-ternary
            !shouldShowNewFlow ? null : shouldDisplayCoupon ? (
              <div
                className={styles.coupon_input_container}
                style={couponCodeError ? { marginBottom: 35 } : null}
              >
                <TextField
                  label="Coupon"
                  variant="outlined"
                  classes={{ root: styles.coupon_textfield }}
                  value={couponCode}
                  onChange={onCouponCodeChange}
                  fullWidth
                  error={!!couponCodeError}
                  helperText={couponCodeError}
                />

                <div className={styles.coupon_code_btn_container}>
                  <button
                    type="button"
                    onClick={onApplyCoupon}
                    className={styles.inverted}
                    style={{ fontSize: 14 }}
                  >
                    {isCouponCodeBtnLoading ? (
                      <div>
                        <CircularProgress size={22} color="inherit" />
                      </div>
                    ) : (
                      <span>apply</span>
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <div
                onClick={() => setShouldDisplayCoupon(true)}
                style={{ cursor: 'pointer' }}
              >
                <p style={{ fontWeight: 900, color: '#4be0d0' }}>
                  Have a coupon code?
                </p>
                <p style={{ fontWeight: 900, color: '#4be0d0' }}>
                  <ExpandMoreIcon />
                </p>
              </div>
            )}
          </div>
          <div className={styles.divider} />
          <div className={styles.total_info}>
            {(currentPlanDetails.savings > 0 || couponDiscount.amount > 0) && (
              <div>
                <p className={styles.light_green}>Your savings today</p>
                <p className={styles.light_green}>
                  $
                  {parseFloat(
                    (
                      currentPlanDetails.savings +
                      getDiscountedAmountBasedOnCoupon()
                    ).toFixed(2),
                    10
                  )}
                </p>
              </div>
            )}
            <div>
              <p>Total due today</p>
              <p>
                $
                {Math.max(
                  (totalDue - getDiscountedAmountBasedOnCoupon()).toFixed(2),
                  0
                )}
              </p>
            </div>
          </div>
          {shouldShowNewFlow ? null : (
            <div className={styles.cart_notes}>
              <p style={{ display: 'none' }}>
                Your plan will renew on {currentPlanDetails.nextBillingDate},
                and you will be charged ${currentPlanDetails.price}.
              </p>
              {currentPlanDetails.weeksToBePaid > 4 && (
                <p>We will notify you before the plan renews.</p>
              )}
            </div>
          )}
          <div className={styles.btn_container}>
            <button
              type="button"
              onClick={onContinueBtnClick}
              style={shouldShowNewFlow ? { width: '100%' } : null}
            >
              <span>{buttonText}</span>
            </button>
          </div>
          {shouldShowNewFlow && currentStep === stepperSteps.paymentB ? (
            <div className={styles.cart_notes} style={{ marginTop: 20 }}>
              We will notify you before the plan renews. At the end of the
              subscription period, you can choose to switch to the lower-cost
              Continuity Training or renew/cancel your plan.
            </div>
          ) : null}
        </div>
        {shouldShowNewFlow ? null : (
          <div className={styles.btn_container_mobile}>
            <button type="button" onClick={onContinueBtnClick}>
              <span>{buttonText}</span>
            </button>
          </div>
        )}
        {shouldShowNewFlow ? null : (
          <p className={styles.transactions_are_secured}>
            All transactions are secure and encrypted.
          </p>
        )}
      </div>
    );
  };
  Cart.propTypes = {
    buttonText: PropTypes.string,
  };

  const renderShippingB = () => {
    return (
      <div
        className={classNames(
          styles.shipping_and_payment_container,
          styles.slide_in_left
        )}
      >
        {shouldShowNewFlow && isMatchingMDScreen ? (
          <div
            className={styles.btn_container_mobile}
            style={{ marginTop: 10 }}
          >
            <button
              type="button"
              onClick={onContinueBtnClick}
              style={{ width: '100%' }}
            >
              <span>Continue</span>
            </button>
          </div>
        ) : null}
        <div className={styles.shipping_and_payment_inputs_container}>
          {isMatchingMDScreen ? (
            <div className={styles.main_divider} style={{ marginBottom: 30 }} />
          ) : null}
          <div
            className={styles.shipping_and_payment_inputs}
            id="shipping_information"
          >
            <div className={styles.shipping_and_payment_inputs_title_container}>
              <div className={styles.shipping_and_payment_inputs_title}>
                Shipping Information
              </div>
              <div
                style={{ display: 'flex', fontSize: 14, alignItems: 'center' }}
              >
                <ShippingIcon style={{ marginRight: 10 }} />
                Free shipping
              </div>
            </div>
            <div
              className={styles.shipping_info_step}
              style={{ marginBottom: 0, flexDirection: 'column' }}
            >
              <div
                className={styles.inputs}
                style={{ display: 'flex', maxWidth: '100%', marginBottom: 0 }}
              >
                <TextField
                  label="First name"
                  error={!!shippingFormErrors.firstName}
                  helperText={shippingFormErrors.firstName}
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  value={shippingFormValues.firstName || ''}
                  onChange={createOnTextChangeCallback('firstName')}
                  inputProps={{
                    name: 'firstName',
                  }}
                  style={{ marginRight: 20 }}
                />
                <TextField
                  label="Last name"
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  onChange={createOnTextChangeCallback('lastName')}
                  error={!!shippingFormErrors.lastName}
                  value={shippingFormValues.lastName || ''}
                  helperText={shippingFormErrors.lastName}
                  inputProps={{
                    name: 'lastName',
                  }}
                />
              </div>
              <div
                className={styles.inputs}
                style={{
                  display: 'flex',
                  maxWidth: '100%',
                  marginBottom: 0,
                  flexDirection: isMatchingMDScreen ? 'column' : 'row',
                }}
              >
                <TextField
                  label="Email"
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  onChange={createOnTextChangeCallback('email')}
                  error={!!shippingFormErrors.email}
                  value={shippingFormValues.email || ''}
                  helperText={shippingFormErrors.email}
                  inputProps={{
                    name: 'email',
                  }}
                  style={{ marginRight: 20 }}
                />
                <TextField
                  label="Country"
                  select
                  name="Country"
                  onChange={onCountryChange}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                  fullWidth
                  value={shippingFormValues.country}
                  inputProps={{
                    name: 'country',
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    classes: {
                      root: styles.select_country_root,
                    },
                  }}
                >
                  {filteredCountries.map(country => (
                    <option key={country.iso2} value={country.iso2}>
                      {country.name}
                    </option>
                  ))}
                </TextField>
              </div>
              <div
                className={styles.inputs}
                style={{ display: 'flex', maxWidth: '100%', marginBottom: 0 }}
              >
                {shippingFormValues.country === 'us' ||
                shippingFormValues.country === 'ca' ? (
                  <TextField
                    // label="State"
                    select
                    name="State"
                    onChange={onStateChange}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                    fullWidth
                    value={shippingFormValues.state}
                    inputProps={{
                      name: 'state',
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      classes: {
                        root: styles.select_country_root,
                      },
                    }}
                    error={!!shippingFormErrors.state}
                    style={{ marginRight: 20 }}
                  >
                    {(shippingFormValues.country === 'us'
                      ? usaStates
                      : canadaProvinces
                    ).map(state => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.name}
                      </option>
                    ))}
                  </TextField>
                ) : (
                  <TextField
                    label="State"
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    onChange={createOnTextChangeCallback('state')}
                    error={!!shippingFormErrors.state}
                    value={shippingFormValues.state || ''}
                    helperText={shippingFormErrors.state}
                    inputProps={{
                      name: 'state',
                    }}
                    style={{ marginRight: 20 }}
                  />
                )}
                {!isMatchingMDScreen ? null : (
                  <TextField
                    label="Zip/Postal Code"
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    onChange={createOnTextChangeCallback('zipCode')}
                    error={!!shippingFormErrors.zipCode}
                    value={shippingFormValues.zipCode || ''}
                    helperText={shippingFormErrors.zipCode}
                    inputProps={{
                      name: 'zipCode',
                    }}
                    // style={{marginRight: 20}}
                  />
                )}
                {isMatchingMDScreen ? null : (
                  <TextField
                    label="City"
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    onChange={createOnTextChangeCallback('city')}
                    error={!!shippingFormErrors.city}
                    value={shippingFormValues.city || ''}
                    helperText={shippingFormErrors.city}
                    inputProps={{
                      name: 'city',
                    }}
                  />
                )}
              </div>
              <div
                className={styles.inputs}
                style={{
                  display: 'flex',
                  maxWidth: '100%',
                  marginBottom: 0,
                  flexDirection: isMatchingMDScreen ? 'column' : 'row',
                }}
              >
                {!isMatchingMDScreen ? null : (
                  <TextField
                    label="City"
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    onChange={createOnTextChangeCallback('city')}
                    error={!!shippingFormErrors.city}
                    value={shippingFormValues.city || ''}
                    helperText={shippingFormErrors.city}
                    inputProps={{
                      name: 'city',
                    }}
                  />
                )}
                <TextField
                  label="Street, P.O. Box, etc…"
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  onChange={createOnTextChangeCallback('street')}
                  error={!!shippingFormErrors.street}
                  value={shippingFormValues.street || ''}
                  helperText={shippingFormErrors.street}
                  inputProps={{
                    name: 'street',
                  }}
                  style={{ marginRight: 20 }}
                />

                <TextField
                  label="Apt, Floor, etc.. (optional)"
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  onChange={createOnTextChangeCallback('apt')}
                  error={!!shippingFormErrors.apt}
                  value={shippingFormValues.apt || ''}
                  helperText={shippingFormErrors.apt}
                  inputProps={{
                    name: 'apt',
                  }}
                />
              </div>
              <div
                className={styles.inputs}
                style={{
                  display: 'flex',
                  maxWidth: '100%',
                  marginBottom: 0,
                  flexDirection: isMatchingMDScreen ? 'column' : 'row',
                  position: 'relative',
                }}
              >
                {isMatchingMDScreen ? null : (
                  <TextField
                    label="Zip/Postal Code"
                    variant="outlined"
                    classes={{ root: styles.textfield }}
                    fullWidth
                    onChange={createOnTextChangeCallback('zipCode')}
                    error={!!shippingFormErrors.zipCode}
                    value={shippingFormValues.zipCode || ''}
                    helperText={shippingFormErrors.zipCode}
                    inputProps={{
                      name: 'zipCode',
                    }}
                    style={{ marginRight: 20 }}
                    FormHelperTextProps={{
                      classes: { root: styles.helperText },
                    }}
                  />
                )}

                <PhoneInput
                  country={shippingFormValues.country}
                  value={shippingFormValues.phone || ''}
                  onChange={createOnTextChangeCallback('phone')}
                  inputProps={{
                    name: 'phone',
                  }}
                  placeholder="Phone (e.g. +9171234567)"
                  label="Phone"
                  variant="outlined"
                  inputClass={
                    shippingFormErrors.phone
                      ? styles.phone_input_error
                      : styles.phone_input
                  }
                  fullWidth
                  // error={!!shippingFormErrors.phone}
                  // helperText={shippingFormErrors.phone}
                />
                {shippingFormErrors.phone ? (
                  <span
                    className={styles.phone_input_message}
                    style={
                      shouldShowNewFlow
                        ? {
                            position: 'absolute',
                            top: 60,
                            left: isMatchingMDScreen ? 0 : 340,
                          }
                        : null
                    }
                  >
                    {shippingFormErrors.phone}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          {shippingFormValues.country !== 'us' && (
            <div style={{ fontSize: 14, color: 'black', maxWidth: 700 }}>
              Please note that countries have their own customs regulations, and
              we are unable to predict or cover any additional delivery charges
              outside of the United States.
            </div>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {/* <Cart buttonText='Continue'/> */}
          {Cart({ buttonText: 'Continue' })}
        </div>
      </div>
    );
  };

  const renderPaymentB = () => {
    return (
      <div
        className={classNames(
          styles.shipping_and_payment_container,
          styles.slide_in_left
        )}
      >
        {shouldShowNewFlow && isMatchingMDScreen ? (
          <div
            className={styles.btn_container_mobile}
            style={{ marginTop: 10 }}
          >
            <button
              type="button"
              onClick={onContinueBtnClick}
              style={{ width: '100%' }}
            >
              <span>PAY</span>
            </button>
          </div>
        ) : null}
        <div className={styles.payment_inputs_container}>
          <div className={styles.shipping_and_payment_inputs}>
            <div
              className={styles.shipping_and_payment_inputs_title_container}
              id="payment_information"
            >
              <div className={styles.shipping_and_payment_inputs_title}>
                Payment Information
              </div>
              <div
                style={{ display: 'flex', fontSize: 14, alignItems: 'center' }}
              >
                <MoneyIcon style={{ marginRight: 10 }} />
                30-day money-back {isMatchingMDScreen ? '' : 'guarantee'}
              </div>
            </div>

            <div className={styles.payment_step}>
              <div
                className={styles.inputs}
                style={{ width: '100%', maxWidth: 'unset', marginBottom: 0 }}
              >
                <div className={styles.stripe_element}>
                  <CardElement
                    onChange={onCreditCardChange}
                    onReady={element => {
                      cardElementRef.current = element;
                    }}
                    style={stripeElementStyle}
                    hidePostalCode
                  />
                  <p className={styles.error}>{creditCardError}</p>
                </div>
                <TextField
                  label="Name on card"
                  error={!!paymentFormErrors.nameOnCard}
                  helperText={paymentFormErrors.nameOnCard}
                  variant="outlined"
                  classes={{ root: styles.textfield }}
                  fullWidth
                  value={paymentFormValues.nameOnCard || ''}
                  onChange={createOnTextChangeCallbackForPaymentForm(
                    'nameOnCard'
                  )}
                  inputProps={{
                    name: 'nameOnCard',
                  }}
                />
              </div>
            </div>

            <div className={styles.secured_by_stripe}>
              <LockIcon style={{ marginRight: 10 }} />
              Secured by Stripe
            </div>
          </div>
          {/* <p style={{ fontSize: 11, textAlign: 'center' }}>
            We will send a registration code to the email entered above once the
            payment is complete.
          </p> */}
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {/* <Cart buttonText='Continue'/> */}
          {Cart({ buttonText: 'Pay' })}
        </div>
      </div>
    );
  };

  const renderCartStep = () => {
    const currentPlanDetails = getCurrentPlanDetails();
    const planNameBasedOnBillingPeriod = getCurrentPlanDetails()
      .nameBasedOnBillingPeriod;
    const planTitle = (() => {
      return (
        <p>
          {formattedPlanTypes[selectedPlanType]}, {planNameBasedOnBillingPeriod}
        </p>
      );
    })();

    const cartStepContainerAnimationClass =
      prvStep === stepperSteps.payment
        ? styles.slide_in_right
        : styles.slide_in_left;

    const getStateText = (country, state) => {
      if (country === 'ca') {
        const ans = canadaProvinces.find(
          state1 => state1.abbreviation.toUpperCase() === state
        );
        return ans ? ans.name : state;
      }
      if (country === 'us') {
        const ans = usaStates.find(
          state1 => state1.abbreviation.toUpperCase() === state
        );
        return ans ? ans.name : state;
      }
      return state;
    };

    const getCountryText = country => {
      return countriesJSON[country.toUpperCase()].name;
    };

    return (
      <div className={cartStepContainerAnimationClass}>
        <div className={styles.cart_step}>
          <div className={styles.info}>
            <div className={styles.card}>
              <div>
                <div className={styles.current_package_info}>
                  <p>Subscription plan</p>
                  {// eslint-disable-next-line no-nested-ternary
                  !shouldRenderNewPlans ? (
                    planTitle
                  ) : isReactivationFlow ? (
                    <div style={{ fontWeight: 900 }}>
                      {`${plans[initialPlanType].planTypeName}, ${currentPlanDetails.nameBasedOnNewPricing}`}
                    </div>
                  ) : (
                    <div style={{ fontWeight: 900 }}>
                      {`${
                        currentPlanDetails.commitmentInMonths
                      } Month Membership${
                        shouldShowFamilyCorePackages ? ' (Family member)' : ''
                      } (${currentPlanDetails.nameBasedOnNewPricing})`}
                    </div>
                  )}
                </div>
                <br />
                {selectedMuseOption === museOptions.includeAMuseDevice && (
                  <div className={styles.current_package_info}>
                    <p>Add on</p>
                    <p>Muse 2 Headband</p>
                  </div>
                )}
              </div>

              <span
                onClick={() => {
                  sendAmplitudeEvent('on_change_plan');
                  logMTRSubscriptionAppEvent({
                    name: 'on_change_plan',
                  });
                  setCurrentStepForChoosePlan(choosePlanSteps.paymentOptions);
                  setCurrentStep(stepperSteps.choosePlan);
                }}
              >
                Change
              </span>
            </div>
            {!userId && !isReactivationFlow && (
              <div className={styles.card}>
                <div className={styles.current_shipment_info}>
                  <p>Ship to:</p>
                  <p>
                    {shippingFormValues.street}{' '}
                    {shippingFormValues.apt
                      ? `, ${shippingFormValues.apt}`
                      : ''}
                  </p>
                  <p>
                    {[
                      shippingFormValues.city,
                      getStateText(
                        shippingFormValues.country,
                        shippingFormValues.state
                      ),
                      getCountryText(shippingFormValues.country),
                      shippingFormValues.zipCode,
                    ]
                      .filter(val => val)
                      .join(', ')}
                  </p>
                </div>
                <span
                  onClick={() => {
                    sendAmplitudeEvent('on_change_shipping_info');
                    logMTRSubscriptionAppEvent({
                      name: 'on_change_shipping_info',
                    });
                    setCurrentStep(stepperSteps.shippingInfo);
                  }}
                >
                  Change
                </span>
              </div>
            )}
            <div>
              {/* <div className={styles.coupon_input_container} style={ABPricingToggle ? {display: 'none'} : null}>
                <TextField
                  label="Coupon"
                  variant="outlined"
                  classes={{ root: styles.coupon_textfield }}
                  value={couponCode}
                  onChange={onCouponCodeChange}
                  fullWidth
                  error={!!couponCodeError}
                  helperText={couponCodeError}
                />

                <div className={styles.coupon_code_btn_container}>
                  <button
                    type="button"
                    onClick={onApplyCoupon}
                    className={styles.inverted}
                  >
                    {isCouponCodeBtnLoading ? (
                      <div>
                        <CircularProgress size={22} color="inherit" />
                      </div>
                    ) : (
                      <span>apply</span>
                    )}
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          {/* <Cart /> */}
          {Cart()}
        </div>
        <div className={styles.note_prices_in_usd}>
          <p>
            {`${
              shouldShowFamilyCorePackages || isReactivationFlow
                ? ''
                : 'Free shipping and'
            }`}{' '}
            {isReactivationFlow || shouldShowFamilyCorePackages
              ? ''
              : '30-day money-back guarantee'}
          </p>
          <p>Note: all prices shown are in USD</p>
        </div>

        {/* {renderFAQS()} */}
      </div>
    );
  };

  const renderPaymentStep = () => {
    return (
      <div className={styles.slide_in_left}>
        <div className={styles.payment_step}>
          <div className={styles.inputs}>
            <TextField
              label="First name"
              error={!!paymentFormErrors.firstName}
              helperText={paymentFormErrors.firstName}
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              value={paymentFormValues.firstName || ''}
              onChange={createOnTextChangeCallbackForPaymentForm('firstName')}
              inputProps={{
                name: 'firstName',
              }}
            />
            <TextField
              label="Last name"
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              onChange={createOnTextChangeCallbackForPaymentForm('lastName')}
              error={!!paymentFormErrors.lastName}
              value={paymentFormValues.lastName || ''}
              helperText={paymentFormErrors.lastName}
              inputProps={{
                name: 'lastName',
              }}
            />
            <TextField
              label="Email"
              variant="outlined"
              classes={{ root: styles.textfield }}
              fullWidth
              onChange={createOnTextChangeCallbackForPaymentForm('email')}
              error={!!paymentFormErrors.email}
              value={paymentFormValues.email || ''}
              helperText={paymentFormErrors.email}
              inputProps={{
                name: 'email',
              }}
            />

            <div className={styles.divider} />

            <div className={styles.stripe_element}>
              <CardElement
                onChange={onCreditCardChange}
                onReady={element => {
                  cardElementRef.current = element;
                }}
                style={stripeElementStyle}
                hidePostalCode
              />
              <p className={styles.error}>{creditCardError}</p>
            </div>

            {stripeError && (
              <div className={styles.stripe_error}>
                <p>{stripeError}</p>
              </div>
            )}

            {/* <div>
              <p className={styles.payment_complete_note}>
                We will send a registration code to the email entered above once
                the payment is complete.
              </p>
            </div> */}
          </div>
          {/* <Cart buttonText="pay" /> */}
          {Cart({ buttonText: 'pay' })}
        </div>
        <div className={styles.note_prices_in_usd}>
          <p>
            {`${
              shouldShowFamilyCorePackages || isReactivationFlow
                ? ''
                : 'Free shipping and'
            }`}{' '}
            {isReactivationFlow || shouldShowFamilyCorePackages
              ? ''
              : '30-day money-back guarantee'}
          </p>
          <p>Note: all prices shown are in USD</p>
        </div>
      </div>
    );
  };

  const isBScreenMobileView =
    currentStep === stepperSteps.choosePlan &&
    !shouldRenderNewPlans &&
    !isReactivationFlow &&
    !shouldShowFamilyCorePackages &&
    isMatchingMDScreen;

  const renderStickyButtonIfNeeded = () => {
    if (isBScreenMobileView) {
      return (
        <div className={styles.option_b_mobile_btn_container}>
          <div style={{ display: 'flex' }}>
            <button
              type="button"
              onClick={onContinueBtnClick}
              className={styles.btn_container_mobile_screen}
            >
              <span>Continue</span>
            </button>
          </div>
        </div>
      );
    }
    return null;
  };

  const isBackBtnHidden =
    currentStep === stepperSteps.choosePlan &&
    currentStepForChoosePlan === choosePlanSteps.paymentOptions;

  const isUserDidEligibilty = JSON.parse(
    // eslint-disable-next-line no-undef
    localStorage.getItem('isUserDidEligibilty')
  );

  const shouldHideRetakeBtn = !isUserDidEligibilty || isReactivationFlow;

  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.logo_and_btn_container}>
        <img
          src={logoImage}
          style={{ width: 100, height: 36, margin: 15, cursor: 'pointer' }}
          onClick={() => {
            window.open('https://www.myndlift.com/', '_blank');
          }}
        />

        <div className={styles.coupon_code_container}>
          {!userId && discountCoupon && (
            <CouponDiscountBanner
              discountCoupon={discountCoupon}
              discountCouponAmount={discountCouponAmount}
              discountCouponText={discountCouponText}
              discountCouponGroupText={
                !isReactivationFlow &&
                plans &&
                plans.bannerText &&
                plans.bannerText.registrationFlow
              }
              customCouponText={customCouponText}
              // couponDiscount={couponDiscount}
              // lastValidCouponCode={lastValidCouponCode}
            />
          )}
        </div>
        <div
          className={styles.retake_quiz}
          onClick={backToEligibilty}
          style={shouldHideRetakeBtn ? { visibility: 'hidden' } : null}
        >
          <RefreshIcon />
          Retake quiz
        </div>
      </div>
      <div className={styles.main_divider} />
      <div
        className={styles.coupon_code_container_mobile}
        style={isBScreenMobileView ? { marginBottom: 0 } : null}
      >
        {!userId && discountCoupon && (
          <CouponDiscountBanner
            discountCoupon={discountCoupon}
            discountCouponAmount={discountCouponAmount}
            discountCouponText={discountCouponText}
            discountCouponGroupText={
              !isReactivationFlow &&
              plans &&
              plans.bannerText &&
              plans.bannerText.registrationFlow
            }
            couponDiscount={couponDiscount}
            lastValidCouponCode={lastValidCouponCode}
            customCouponText={customCouponText}
          />
        )}
      </div>
      <div className={styles.content}>
        <div
          className={classNames(styles.back_icon_container, {
            [styles.hidden]: isBackBtnHidden,
          })}
          onClick={onBackBtnClick}
        >
          <ArrowBackIcon style={{ color: 'inherit', fontSize: 28 }} />
        </div>
        {isBScreenMobileView ? null : (
          <div className={styles.stepper_container}>
            <Stepper steps={orderedStepperSteps} activeStep={currentStep} />
          </div>
        )}

        <div
          className={styles.step_content}
          style={isBScreenMobileView ? { paddingTop: 0 } : null}
        >
          {currentStep === stepperSteps.choosePlan && renderChoosePlanStep()}
          {currentStep === stepperSteps.shippingInfo &&
            renderShippingInfoStep()}
          {currentStep === stepperSteps.cart && renderCartStep()}
          {currentStep === stepperSteps.payment && renderPaymentStep()}
          {currentStep === stepperSteps.shippingB && renderShippingB()}
          {currentStep === stepperSteps.paymentB && renderPaymentB()}
        </div>
      </div>

      {shouldShowLoader && (
        <div>
          <Backdrop open classes={{ root: styles.backdrop }} />

          <div className={styles.progress_container}>
            <CircularProgress />
            {currentStep === stepperSteps.payment && (
              <div style={{ color: 'white' }}>
                Processing payment. This might take up to a few seconds. Please
                do not exit the page.
              </div>
            )}
          </div>
        </div>
      )}

      <FailedPaymentDialog
        isOpen={isPaymentFailedDialogOpen}
        onTryAgain={() => {
          setIsPaymentFailedDialogOpen(false);
        }}
      />

      <AccountReactivatedSuccessfullyDialog
        isOpen={isAccountReactivatedSuccessfullyDialogOpen}
        onDone={() => {
          postMessageToApp('userActivationPaymentDone');
        }}
      />
      <Dialog
        open={!!displayVideoGuide}
        onClose={() => {
          sendAmplitudeEvent('subscription_how_it_works_exit', {
            time_open: Date.now() - displayVideoGuide,
          });
          logMTRSubscriptionAppEvent({
            name: 'subscription_how_it_works_exit',
            time_open: Date.now() - displayVideoGuide,
          });
          setDisplayVideoGuide(false);
        }}
        fullScreen={!!isMatchingXSScreen}
        fullWidth
        maxWidth="md"
      >
        <div
          style={{
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 15,
              marginLeft: 24,
            }}
          >
            <div />
            How it works
            <CloseIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                sendAmplitudeEvent('subscription_how_it_works_exit', {
                  time_open: (Date.now() - displayVideoGuide) / 1000,
                });
                logMTRSubscriptionAppEvent({
                  name: 'subscription_how_it_works_exit',
                  time_open: (Date.now() - displayVideoGuide) / 1000,
                });
                setDisplayVideoGuide(false);
              }}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <iframe
              width="840"
              height="472"
              src="https://www.youtube.com/embed/rCjpqOg3QSQ?si=yk7EiF_IEPLtSsD2&autoplay=1"
              title="YouTube video player"
              frameBorder="0"
              allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              // eslint-disable-next-line react/no-unknown-property
              allowfullscreen
            />
          </div>
          <div />
        </div>
      </Dialog>
      {renderStickyButtonIfNeeded()}
      {isYoutubeDialogOpen && (
        <div
          className={styles.youtube_dialog}
          onClick={() => {
            setIsYoutubeDialogOpen(false);
          }}
        >
          <div className={styles.youtube_dialog_content}>
            <button
              className={styles.youtube_dialog_close_button}
              onClick={() => {
                setIsYoutubeDialogOpen(false);
              }}
              type="button"
            >
              <CloseIcon
                className={styles.youtube_dialog_close_button_icon}
                titleAccess="close"
              />
            </button>
            <iframe
              // width={currentVideoDialogInfo.width}
              // height={currentVideoDialogInfo.height}
              className={styles[currentVideoDialogInfo.className]}
              src={
                currentVideoDialogInfo.className === 'iframe_short_embed'
                  ? `https://www.youtube.com/embed/${currentVideoDialogInfo.videoId}?autoplay=1`
                  : `https://www.youtube.com/embed/${currentVideoDialogInfo.videoId}&amp;autoplay=1`
              }
              title={currentVideoDialogInfo.videoTitle}
              allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              frameBorder="0"
              // eslint-disable-next-line react/no-unknown-property
              allowfullscreen
            />
          </div>
        </div>
      )}
    </div>
  );
};

MTRSubscriptionPageBase.propTypes = exact({
  faqs: PropTypes.array.isRequired,
  stripe: PropTypes.object.isRequired,
  plans: PropTypes.object.isRequired,
  nodeURL: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  museDevicePrice: PropTypes.number.isRequired,
  discountCouponAmount: PropTypes.number.isRequired,
  discountCoupon: PropTypes.string.isRequired,
  additionalSessionPrice: PropTypes.number,
  customerInfo: PropTypes.object,
  isEligibleForContinuityPlan: PropTypes.bool.isRequired,
  hideMuseHeadset: PropTypes.bool,
  shouldRenderNewPlans: PropTypes.bool,
  postMembershipPlan: PropTypes.object,
  extraCredit: PropTypes.number,
  discountCouponText: PropTypes.string,
  funcURL: PropTypes.string.isRequired,
  ABPricingToggle: PropTypes.bool,
  // beforeDiscountPlan: PropTypes.object,
  reviews: PropTypes.array,
  excludedCountries: PropTypes.array,
  deepLinkCouponCode: PropTypes.string,
  customCouponText: PropTypes.string,
  // remoteClinicsInfo: PropTypes.object,
});

export const MTRSubscriptionPage = React.memo(
  injectStripe(MTRSubscriptionPageBase)
);
MTRSubscriptionPage.displayName = 'MTRSubscriptionPage';
